import React, { useEffect, useState } from "react";
import {
  trafficChannelData,
  trafficChannelDataSet2,
  trafficChannelDataSet3,
  trafficChannelDataSet4,
} from "../../charts/analytics/AnalyticsData";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { Icon, DataTableHead, DataTableRow, DataTableItem } from "../../../Component";
import { WPCharts } from "../../charts/analytics/AnalyticsCharts";
import Button from "react-bootstrap/esm/Button";
const TrafficChannel = () => {
  const [dd, setdd] = useState("30");
  const [trafficData, setTrafficData] = useState(trafficChannelData);

  useEffect(() => {
    if (dd === "30") {
      setTrafficData(trafficChannelDataSet3);
    } else if (dd === "15") {
      setTrafficData(trafficChannelDataSet4);
    } else {
      setTrafficData(trafficChannelDataSet2);
    }
  }, [dd]);

  return (
    <React.Fragment>
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">Ongoing subscriptions</h6>
            <p>See which subscription is active.</p>
          </div>
        
        </div>
      </div>
      <div className="nk-tb-list is-loose traffic-channel-table">
        <DataTableHead>
          <DataTableRow className="nk-tb-channel">
            <span>Name</span>
          </DataTableRow>
          <DataTableRow className="nk-tb-sessions">
            <span>Messages left</span>
          </DataTableRow>
          <DataTableRow className="nk-tb-prev-sessions">
            <span>Cost per SMS</span>
          </DataTableRow>
       
       
        </DataTableHead>
        {trafficData.map((item) => {
          return (
            <DataTableItem className="nk-tb-item" key={item.id}>
              <DataTableRow className="nk-tb-channel">
                <span className="tb-lead">{item.name}</span>
              </DataTableRow>
              <DataTableRow className="nk-tb-sessions">
                <span className="tb-sub tb-amount">
                  <span>{item.balance}</span>
                </span>
              </DataTableRow>
              <DataTableRow className="nk-tb-prev-sessions">
                <span className="tb-sub tb-amount">
                  <span>{item.perMessage}$</span>
                </span>
              </DataTableRow>
           
              
            </DataTableItem>
          );
        })}
         <Button color="primary" style={{marginTop:15, marginLeft:15}}>
                        <Icon name="reports"></Icon>
                        <span>Purchase subscriptions</span>
                      </Button>
      </div>
     
    </React.Fragment>
  );
};
export default TrafficChannel;
