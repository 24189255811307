export default function SearchPopUp(){
    const searchModalRemove = () => {
        document.body.classList.remove('open');
    };
    return(
        <div className="search-wrap">
        <div className="search-inner">
            <i className="fas fa-times search-close" id="search-close" onClick={searchModalRemove}></i>
            <div className="search-cell">
                <form method="get">
                    <div className="search-field-holder">
                        <input type="search" className="main-search-input" placeholder="Search Your Keyword..." />
                    </div>
                </form>
            </div>
        </div>
    </div>
    )
}