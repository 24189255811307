import {Link} from "react-router-dom";
import Cookies from "js-cookie";

const MainMenu = () => {
    const signedIn = (Cookies.get('token')?(Cookies.get('token').length>5 && true):false);
    const signedInButtonStyle ={ backgroundColor: "#eff7ff",borderRadius: "45px",color: "#086ad8", display:'flex', justifyContent:"center", alignItems: 'center',}

    return(
        <nav id="topheader" className="d-none d-lg-block navbar navbar-expand-lg justify-content-start py-0 pl-60 pl-lg-0" >
            <ul className="navbar-nav list-style-none">
                
            <li className="nav-item">
                    <Link className="nav-link" to="/">Home</Link>
                </li>
             
            
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown4" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">Products <i className="fal fa-chevron-down"></i></Link>
                    <ul className="dropdown-menu" style={{overflow: 'visible'}}>
                    <li>                                   
                                    <Link className="dropdown-item" to="/smstoolsoverview">Overview</Link>
                                    <Link className="dropdown-item" to="/web-sms-app">Web Texting App</Link>

                                </li>
                    <li className="dropdown-submenu">
                            <a className="dropdown-item dropdown-toggle" to="services"
                                id="navbarDropdow2a" role="button" data-bs-toggle="dropdown"
                                aria-expanded="false">SMS API'S <i
                                    className="fal fa-chevron-down"></i></a>
                            <ul className="dropdown-menu dropdown-menu2"
                                aria-labelledby="navbarDropdown2a">
                                <li>                                   
                                    <Link className="dropdown-item" to="/smsapi">Send message API </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/verificationapi">SMS Verification API</Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/bulksmsapi">Bulk SMS API</Link>
                                </li>

                               
                            </ul>
                        </li>
                        <li className="dropdown-submenu">
                           
                                    <Link className="dropdown-item" to="/bulksms">No-code/Bulk SMS tools</Link>
                                                         
                        </li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown4" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">Industries <i className="fal fa-chevron-down"></i></Link>
                    <ul className="dropdown-menu" style={{overflow: 'visible'}}>
                    <li>                                   
                                    <Link className="dropdown-item" to="/trucking">Trucking</Link>
                                    <Link className="dropdown-item" to="/sms-marketing">Marketing</Link>
                                    <Link className="dropdown-item" to="/saas">SAAS</Link>

                                </li>
                   
                       
                    </ul>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/pricing">Pricing</Link>
                </li>
              
              
                <li className="nav-item">
                    <Link className="nav-link" to="/contact">Contact</Link>
                </li>
                <li className={!signedIn&&"nav-item"} style={signedIn?signedInButtonStyle:{}}>
                    <a className={signedIn?"theme_btn quote-btn":"nav-link"}  href={signedIn?"/dashboard":"/signin"}>{signedIn?"Dashboard":"SignIn"}</a>
                </li>
              
            </ul>
        </nav>
    )
}
export default MainMenu