import React from "react";
import { DataTableHead, DataTableRow, DataTableItem } from "../../components/Component";
import Button from "react-bootstrap/esm/Button";
import {Icon} from "../../components/Component";
import { useNavigate } from "react-router";

const SubscriptionsCard = ({ subscriptions }) => {
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">Ongoing Subscriptions</h6>
            <p>See which subscription is active.</p>
          </div>
        </div>
      </div>
      <div className="nk-tb-list is-loose traffic-channel-table">
        <DataTableHead>
          <DataTableRow className="nk-tb-channel">
            <span>Name</span>
          </DataTableRow>
          <DataTableRow className="nk-tb-sessions">
            <span>Messages Left</span>
          </DataTableRow>
          <DataTableRow className="nk-tb-prev-sessions">
            <span>Cost per SMS</span>
          </DataTableRow>
        </DataTableHead>
        {subscriptions.map((subscription, index) => (
          <DataTableItem className="nk-tb-item" key={index}>
            <DataTableRow className="nk-tb-channel">
              <span className="tb-lead">{subscription.name}</span>
            </DataTableRow>
            <DataTableRow className="nk-tb-sessions">
              <span className="tb-sub tb-amount">
                <span>{subscription.messagesLeft}</span>
              </span>
            </DataTableRow>
            <DataTableRow className="nk-tb-prev-sessions">
              <span className="tb-sub tb-amount">
                <span>{subscription.costPerMessage}$</span>
              </span>
            </DataTableRow>
          </DataTableItem>
        ))}
        <Button color="primary" style={{ marginTop: 15, marginLeft: 15 }} onClick={() =>{navigate("/dashboard/pricing")}}>
          <Icon name="reports"></Icon>
          <span>Manage Subscriptions</span>
        </Button>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionsCard;
