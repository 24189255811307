import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { QRCodeCanvas } from 'qrcode.react';
import CopyButton from './CopyButton.js'

function CryptoPaymentModal({ cryptoPaymentModalShown, setCryptoPaymentModalShown, amountInChosenCrypto, walletAddress, selectedCurrency }) {

  return (
    <Modal isOpen={cryptoPaymentModalShown} toggle={() => setCryptoPaymentModalShown(false)}>
      <ModalHeader
        toggle={() => setCryptoPaymentModalShown(false)}
        close={
          <button className="close" onClick={() => setCryptoPaymentModalShown(false)}>
            X {/* Replace with your Icon if needed */}
          </button>
        }
      >
        Send Payment
      </ModalHeader>
      <ModalBody>
        <div className="text-center">
          <p>Please send the <strong>EXACT</strong> amount of <strong>{amountInChosenCrypto} {selectedCurrency}</strong> to the wallet address below:</p>
          <p>Once we have recieved your payment, you will get a notification to your registered email address and your account will be credited.</p>
          {/* Display QR Code */}
          <QRCodeCanvas value={walletAddress} size={256} level={"H"} includeMargin={true} />
          <p>{walletAddress}</p>
          {/* Use CopyButton here */}
          <CopyButton copyText={walletAddress} />
        </div>
      </ModalBody>
      <ModalFooter className="bg-light">
        {/* Any footer content if needed */}
      </ModalFooter>
    </Modal>
  );
}

export default CryptoPaymentModal;

