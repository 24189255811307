import { Link } from "react-router-dom";
export default function Breadcrumb({ title, subtitle, pagetitle }){
    return(
        <section class="page-title-area title-area3" style={{backgroundImage: `url(assets/img/page-title/page-title-bg3.jpg)`}}>
            <div class="container">
                <div class="row align-items-center pt-140 pt-xs-60">
                    <div class="col-lg-8 col-md-7">
                        <div class="page-title-wrapper text-start">
                            <p class="fs-18 mb-1">{subtitle}</p>
                            <h2 class="page-title text-heding">{title}</h2>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-5 text-md-end">
                        <ul class="breadcrumb list-none breadcrumb-bg">
                            <li><Link to="/">Home</Link></li>
                            <li class="active text-heding fw-bold" aria-current="page">{pagetitle}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
