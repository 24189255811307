export default function AboutSection(){
    return(
        <>
            <section className="about-us-area pt-145 pb-90 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="about-text-wrapper2 mb-30 pr-75">
							<h6 className="semi-title semi-title2">About Company</h6>
							<h2 className="sect-title text-heding2 mb-35">Bringing New IT Business Solutions</h2>
							<ul className="text-list text-list2 list-none border-bottom">
								<li>
									<div className="services-list">
										<h5 className="fs-20 text-heding2">Engaging Group Discussion</h5>
										<p>Automotive has sought to change the way customers think about
											auto service by providing knowledgeable</p>
									</div>
								</li>
								<li>
									<div className="services-list mb-0">
										<h5 className="fs-20 text-heding2">Software Engineering</h5>
										<p>Automotive has sought to change the way customers think about
											auto service by providing knowledgeable</p>
									</div>
								</li>
							</ul>
							<p className="paragraph-title">We help our clients succeed by creating brand identities,
								digital xperiences, and print materials.</p>
							<h6 className="about-author2">Alonso D. Wilimton <span className="text-theme2">-Head Of Idea </span>
							</h6>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="about-img-wrapper mb-30">
							<img className="w-100 img-one-a d-block d-xxl-none" src="assets/img/about/about-1.jpg" alt="About Img" />
							<img className="img-one img-fluid d-none d-xxl-inline-block rounded-3" src="assets/img/about/about-4.jpg" alt="About Img" />
							<img className="img-two img-fluid d-none d-xxl-inline-block rounded-3" src="assets/img/about/about-8.jpg" alt="About Img" />
							<div className="experience-box2">
								<div className="inner-content">
									<img src="assets/img/about/trophy-2.png" alt="icon" />
									<h3>30</h3>
									<p>Years Experience</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
        </>
    )
}