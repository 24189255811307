import React, { useState, useEffect } from "react";
import Icon from "../../components/icon/Icon";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import menu from "../menu/MenuData";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";

const HeaderSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const isSubUser = Cookies.get("subuser") == "true"

  // Debounce function
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, delay);
    };
  };

  // Function to filter searchable items including submenu items
  const getSearchableItems = (menuItems) => {
    return menuItems.reduce((acc, item) => {
      // If the item has a subMenu, add its items instead of the main item
      if (item.subMenu) {
        acc.push(...item.subMenu.map(subItem => ({ ...subItem, parent: item.text })));
      } else if (item.link) { // Add the item if it has a link
        acc.push(item);
      }
      return acc;
    }, []);
  };

  // Search handler with debounce
  const handleSearch = debounce((term) => {
    if (term) {
      const searchableItems = getSearchableItems(menu);
      const filtered = searchableItems.filter(item =>
        item.keyWords && item.keyWords.some(keyword => 
          keyword.toLowerCase().includes(term.toLowerCase()))
      );
      setFilteredResults(filtered);
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, 1200);

  const navigateToScreen = (link) => {
    navigate(link);
    setIsModalOpen(false);
  };

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Icon name="search" style={{ marginRight:10}}></Icon>
        <input 
          style={{ width: '100%', borderColor: 'white' }} 
          className="form-control border-transparent form-focus-none" 
          type="text" 
          placeholder={ isSubUser ? "Search for tools and information across TextLink":"Search for stuff by key words (API, analytics, send sms, pricing, setup, ...) " }
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
      
        <ModalBody>
        <input 
          style={{ borderColor: 'white', margin:5, borderWidth: '1px', borderColor:"gray"}} 
          className="form-control border-transparent form-focus-none" 
          type="text" 
          placeholder={ isSubUser ? "Search for what you need across our app ":"Search for stuff by key words (API, analytics, send sms, pricing, setup, ...) " }
          value={searchTerm}
          onChange={handleSearchChange}
        />
          {filteredResults.map((item, index) => (
            <div key={index} style={{ padding: '10px 0', borderBottom: '1px solid #eee' }}>
              <Icon name={item.icon}></Icon>
              <a 
                onClick={(e) => {
                  e.preventDefault();
                  navigateToScreen(item.link);
                }} 
                style={{ marginLeft: '10px', color: '#007bff', textDecoration: 'none', fontWeight: 'bold', cursor: 'pointer' }}
              >
                {item.parent ? `${item.parent} - ${item.text}` : item.text}
              </a>
              <p style={{ margin: '5px 0 0' }}>{item.description}</p>
            </div>
          ))}
          {filteredResults.length === 0 && <p>No results found.</p>}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default HeaderSearch;