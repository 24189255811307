import React from "react";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../Dashboard/components/Component";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import Head from "../../components/Head";

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const { token } = useParams();
    const navigate = useNavigate()

    const handleChangePassword = async (e) => {
        e.preventDefault();
        const url = global.SERVIP + '/auth/reset-password';
        const jwtToken = Cookies.get('token');
        if (password.length < 8) {
            responseAlert("Password too short", false)
            return
        }
        if (password != repeatedPassword) {
            responseAlert("Passwords do not match", false)
            return
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${jwtToken}` // Set JWT token in the Authorization header
                },
                body: JSON.stringify({
                    password,
                    token
                })
            });
            if (!response.ok) {
                responseAlert("Server error", true)
            }

            const data = await response.json();

            if (data.ok) {
                await responseAlert(data.message, true);
                Cookies.set('token', data.token, { expires: 15, path: '/' });
                navigate('/dashboard');

            } else {
                responseAlert(data.message, false)
            }
        } catch (error) {
            console.error('Error changing password:', error);
            alert('Error changing password');
        }
    };
    const responseAlert = async (message, responseOk) => {
        await Swal.fire({
            icon: responseOk ? "success" : "error",
            title: responseOk ? "Password changed" : "Error",
            text: message,
            focusConfirm: false,
        });
    };

    return (
        <>
                                  <Head title="Reset password Page" />

            <div style={{ height: '50px' }} />
            <Block className="nk-block-middle nk-auth-body  wide-xs" >
                <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
                    <BlockHead>
                        <BlockContent>
                            <BlockTitle tag="h5">Change Password</BlockTitle>
                            <BlockDes>
                                <p>Enter the new password you wish to set.</p>
                            </BlockDes>
                        </BlockContent>
                    </BlockHead>
                    <form onSubmit={handleChangePassword}>
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="old-password">Password</label>
                            </div>
                            <input
                                type="password"
                                className="form-control form-control-lg"
                                id="old-password"
                                placeholder="Enter your new password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <div className="form-label-group">
                                <label className="form-label" htmlFor="new-password">Repeat password</label>
                            </div>
                            <input
                                type="password"
                                className="form-control form-control-lg"
                                id="new-password"
                                placeholder="Type the same password again"
                                value={repeatedPassword}
                                onChange={(e) => setRepeatedPassword(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <Button color="primary" size="md" outline={true} className="btn-block" type="submit">
                                Change Password
                            </Button>
                        </div>
                    </form>
                    <div className="form-note-s2 text-center pt-4">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            <strong>Back</strong>
                        </Link>
                    </div>
                </PreviewCard>
            </Block>
        </>
    );
};

export default ResetPassword;
