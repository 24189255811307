import { Button } from "reactstrap";
import Swal from "sweetalert2";

function SellingAnalyticsDataCard({ data }) {
    if (!data) {
        return (<a>No data available</a>);
    }
    return (
        <>
            <div className="card-title-group align-start mb-0">
                <div className="card-title">
                    <h6 className="subtitle">Withdrawable now</h6>
                </div>
            </div>
            <div className="card-amount">
                <span className="amount">
                    {data.withdrawableNow} <span className="currency currency-usd">USD</span>
                </span>
            </div>
            <div className="invest-data">
                <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                        <div className="title">Total messages sent</div>
                        <span className="amount">
                            {data.totalMessagesSent}<span className="currency currency-usd"> </span>
                        </span>
                        <div className="title" style={{ marginTop: 10 }}>Earnings</div>
                        <span className="amount" style={{ color: 'green' }}>
                            {parseFloat(data.lifetimeEarnings).toFixed(2)}<span style={{ color: 'green' }} className="currency currency-usd"> USD</span>
                        </span>
                    </div>
                    <div className="invest-data-history">
                        <div className="title">Messages this month</div>
                        <span className="amount">
                            {data.messagesThisMonth} <span className="currency currency-usd"> </span>
                        </span>

                    </div>

                </div>
            </div>
            <div className="invest-data-history" style={{ marginTop: 10 }}>
                <div className="title">Minimum withdraw amount</div>
                <span className="amount">
                    10.00 USD
                </span>
            </div>
            <Button onClick={() => {
                if (data.withdrawableNow >= 0.001)
                    Swal.fire({ title: 'Withdraw credits', text: 'To withdraw credits, contact us on help@textlinksms.com using your account email address. Provide us with your preferred withdrawal method (paypal/crypto wallet/bank account).', icon: 'info' });
                else
                    Swal.fire({ title: 'Could not withdraw credits', text: 'Minimum withdrawable amount is 10 USD.', icon: 'error' });
            }} color="primary" className="btn-sm" style={{ margin: 15 }}>
                Withdraw now
            </Button>

        </>
    );
}

export default SellingAnalyticsDataCard;
