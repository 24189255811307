import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert } from 'reactstrap';

const CreateSubuser = ({ token, onCreate }) => {
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  const toggle = () => {
    if (!modal) {
      setEmail('');
    }
    setModal(!modal);
    setMessage(null); // Reset message when opening/closing modal
    setIsError(false); // Reset error state when opening/closing modal
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    if (!isValidEmail(email)) {
      setMessage('Please enter a valid email address.');
      setIsError(true);
      return;
    }

    const url = `${global.SERVIP}/user/create-subuser`;
    const body = { email };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      const result = await response.json();

      if (result.ok) {
        onCreate(result.subuser);
        setEmail("");
        setMessage('The confirmation email has been sent to the user!');
        setIsError(false);
      } else {
        setMessage(result.message || 'Server error');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Network error');
      setIsError(true);
    }
  };

  return (
    <>
      <Button
        className="btn-dim"
        outline
        color="primary"
        style={{ width: "100%", marginBottom: 10, marginTop: 10 }}
        onClick={toggle}
      >
        Create new
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Create Subuser</ModalHeader>
        <ModalBody>
          {message && (
            <Alert color={isError ? 'danger' : 'success'}>
              {message}
            </Alert>
          )}
          <Form onSubmit={(e)=>{e.preventDefault(); handleSubmit()}}>
            <FormGroup>
              <p>Please enter the email address for the new subuser you want to create.</p>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter email"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>OK</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateSubuser;
