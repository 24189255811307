export default function ServicesOne(){
    return(
        <>
        <section className="services-area pt-120 pb-120 pt-md-60 pt-xs-60 pb-md-60 pb-xs-60">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6">
							<div className="single-services s-border mb-30">
								<div className="s-icon">
									<img src="assets/img/icon/s-1.png" alt="Services icon" />
								</div>
								<h6>Service 01</h6>
								<h4 className="sub-title">Cyber Security & Data</h4>
								<ul className="text-list">
									<li>Objectively innovate</li>
									<li>Holisticly predominate</li>
									<li>Completely pursue</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-services s-border mb-30">
								<div className="s-icon">
									<img src="assets/img/icon/s-1a.png" alt="Services icon" />
								</div>
								<h6>Service 02</h6>
								<h4 className="sub-title">Infrastructure Plan</h4>
								<ul className="text-list">
									<li>Objectively innovate</li>
									<li>Holisticly predominate</li>
									<li>Completely pursue</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-services s-border mb-30">
								<div className="s-icon">
									<img src="assets/img/icon/s-1b.png" alt="Services icon" />
								</div>
								<h6>Service 03</h6>
								<h4 className="sub-title">IT Management</h4>
								<ul className="text-list">
									<li>Objectively innovate</li>
									<li>Holisticly predominate</li>
									<li>Completely pursue</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-services s-border mb-30">
								<div className="s-icon">
									<img src="assets/img/icon/s-4a.png" alt="Services icon" />
								</div>
								<h6>Service 04</h6>
								<h4 className="sub-title">QA & Testing</h4>
								<ul className="text-list">
									<li>Objectively innovate</li>
									<li>Holisticly predominate</li>
									<li>Completely pursue</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-services s-border mb-30">
								<div className="s-icon">
									<img src="assets/img/icon/s-4b.png" alt="Services icon" />
								</div>
								<h6>Service 01</h6>
								<h4 className="sub-title">IT Counsultancy</h4>
								<ul className="text-list">
									<li>Objectively innovate</li>
									<li>Holisticly predominate</li>
									<li>Completely pursue</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-services s-border mb-30">
								<div className="s-icon">
									<img src="assets/img/icon/s-4c.png" alt="Services icon" />
								</div>
								<h6>Service 02</h6>
								<h4 className="sub-title">Business Reformm</h4>
								<ul className="text-list">
									<li>Objectively innovate</li>
									<li>Holisticly predominate</li>
									<li>Completely pursue</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
        </>
    )
}