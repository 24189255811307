import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';

const DeleteSubuser = ({ token, id, onDelete }) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setMessage(null); // Reset message when opening/closing modal
    setIsError(false); // Reset error state when opening/closing modal
  };

  const handleDelete = async () => {
    const url = `${global.SERVIP}/user/delete-subuser`;
    const body = { id };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });
      const result = await response.json();

      if (result.ok) {
        onDelete(id);
        setMessage('Subuser has been deleted successfully.');
        setIsError(false);
        setTimeout(toggle, 2000); // Close modal after a short delay
      } else {
        setMessage(result.message || 'Server error');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Network error');
      setIsError(true);
    }
  };

  return (
    <>
      <a
        href="#toggle"
        onClick={(ev) => {
          ev.preventDefault();
          ev.stopPropagation();
          toggle();
        }}
        className="link link-primary"
      >
        Delete
      </a>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Delete Subuser</ModalHeader>
        <ModalBody>
          {message && (
            <Alert color={isError ? 'danger' : 'success'}>
              {message}
            </Alert>
          )}
          <p>Are you sure you want to delete this subuser?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>Delete</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeleteSubuser;
