import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { ChatContext } from './ChatContext';

const TabOverlay = ({ tabs, setValue }) => {
    const { activeTabState } = useContext(ChatContext);
    const [activeTab, setActiveTab] = activeTabState;

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setValue(tab);
    };

    const styles = {
        tabOverlay: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: "10px"
        },
        tabButtons: {
            display: 'flex',
            width: '100%',
        },
        tabButton: {
            flex: 1,
            paddingBottom: "6px",

            textAlign: 'center',
            cursor: 'pointer',
            border: 'none',
            background: 'none',
            fontSize: '14px',
            fontWeight: 600,
            color: '#8094ae',
            transition: 'color 0.3s ease, border-bottom 0.3s ease',
        },
        activeTabButton: {
            color: '#7F8DFF',
            borderBottom: '3px solid #7F8DFF',
        },
        inactiveTabButton: {
            color: '#8094ae',
            borderBottom: '2px solid #ccc',
        },
    };

    return (
        <div style={styles.tabOverlay}>
            <div style={styles.tabButtons}>
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        style={{
                            ...styles.tabButton,
                            ...(JSON.stringify(activeTab) === JSON.stringify(tab) ? styles.activeTabButton : styles.inactiveTabButton),
                        }}
                        onClick={() => handleTabClick(tab)}
                    >
                        {tab.name.toUpperCase()}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default TabOverlay;
