import React, { useContext } from "react";
import { Icon, UserAvatar } from "../../../components/Component";
import SimpleBar from "simplebar-react";
import { Input, Button } from "reactstrap";
import { ChatItem, ContactItem } from "./ChatPartials";
import { findUpper } from "../../../utils/Utils";
import { chatData } from "./ChatData";
import { ChatContext } from "./ChatContext";
import { Select } from 'antd';
import TabOverlay from "./TabOverlay";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from "js-cookie";
const { Option } = Select;

function formatString(str) {
  const totalMaxLen = 16;
  const padLen = 6;
  const ellipsis = '...';

  if (str.length > totalMaxLen) {
    const start = str.substring(0, padLen);
    const end = str.substring(str.length - padLen);
    str = start + ellipsis + end;
  }

  return str;
}

export const ChatAsideBody = ({
  onInputChange,
  setSelectedId,
  selectedId,
  chatItemClick,
  filteredChatList,
  apiKeys,
  selectedApiKey,
  setSelectedApiKey
}) => {
  const { fav, favAction, filterState, contactsState, favContactsState, activeTabState } = useContext(ChatContext);
  const [filter, setFilter] = filterState
  const [activeTab, setActiveTab] = activeTabState
  const [contacts, setContacts] = contactsState
  const [favContacts, setFavContacts] = favContactsState
  const [favData] = fav;
  const [tabs, setTabs] = useState([{ name: "All", value: () => { return true } }])
  const defaultChat = filteredChatList.filter((item) => item.group !== true);
  const isSubUser = Cookies.get("subuser") == "true"

  useEffect(() => {
    const newTabs = [
      ...((Object.keys(favContacts).length) ? [{ name: "My Contacts", value: (recipient) => { return favContacts[recipient.name] } }] : []),
      ...((Object.keys(contacts).length) ? [{ name: "All Contacts", value: (recipient) => { return contacts[recipient.name] } }] : []),
      { name: "All Chats", value: () => { return true } }
    ]

    setTabs(newTabs)
    if (JSON.stringify(tabs) != JSON.stringify(newTabs)) {
      setActiveTab(newTabs[0]);
    }
    else {
      const activeTabName = activeTab.name;
      for (const tab of newTabs) {
        if (activeTabName === tab.name) {
          activeTab.value = tab.value;
        }
      }
    }
  }, [contacts, favContacts])

  return (
    <SimpleBar className="nk-chat-aside-body">
    
      <div className="nk-chat-list">
        {!isSubUser && <>
          <h6 style={{ marginBottom: 10 }} className="title overline-title-alt">Api key</h6>

          <Select value={selectedApiKey} defaultValue={null} placeholder="Select Api Key" style={{ flexBasis: 'calc(25% - 10px)', flexGrow: 1, width: "100%", textAlign: "center", marginBottom: 20 }} onChange={setSelectedApiKey}
          >
            {apiKeys.map(apiKey => <Option style={{ textAlign: 'center' }} value={apiKey.id}>{(apiKey.master ? "Master - " : "") + formatString(apiKey.api_key)}</Option>)}
          </Select>
        </>}

        <TabOverlay setValue={setFilter} tabs={tabs}></TabOverlay>
        <ul className="chat-list" style={{ paddingLeft: "0px" }}>
          {defaultChat.length !== 0 ? (
            filteredChatList.map((item, idx) => {
              return (
                item.convo.length > 0 &&
                !item.group &&
                !item.archive && (
                  <ChatItem
                    key={idx}
                    item={item}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    chatItemClick={chatItemClick}
                  ></ChatItem>
                )
              );
            })
          ) : (
            <p className="m-3">No contacts found.</p>
          )}
        </ul>
      </div>
    </SimpleBar>
  );
};

export const ChannelAsideBody = ({
  filteredChatList,
  onInputChange,
  setSelectedId,
  setMobileView,
  selectedId,
  chatItemClick,
}) => {
  const defaultChat = filteredChatList.filter((item) => item.group === true);
  return (
    <SimpleBar className="nk-chat-aside-body">
      <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <Input
              type="text"
              className="form-round"
              id="default-03"
              placeholder="Search by name or phone"
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="nk-chat-aside-panel nk-chat-channel">
        <h6 className="title overline-title-alt">All Channels</h6>
        <ul className="channel-list">
          {chatData.map((item, idx) => {
            return (
              item.channel && (
                <li
                  key={idx}
                  onClick={() => {
                    setSelectedId(item.id);
                    if (window.innerWidth < 860) setMobileView(true);
                  }}
                >
                  <a
                    href="#name"
                    onClick={(ev) => ev.preventDefault()}
                    className={selectedId === item.id ? "active" : ""}
                  >
                    # {item.name}
                  </a>
                </li>
              )
            );
          })}
        </ul>
      </div>
      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">Teams / Groups</h6>
        <ul className="chat-list">
          {defaultChat.length !== 0 ? (
            filteredChatList.map((item, idx) => {
              return (
                item.convo.length > 0 &&
                item.group &&
                !item.archive &&
                !item.channel && (
                  <ChatItem
                    key={idx}
                    item={item}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    chatItemClick={chatItemClick}
                  ></ChatItem>
                )
              );
            })
          ) : (
            <p className="m-3">No group found</p>
          )}
        </ul>
      </div>
    </SimpleBar>
  );
};

export const ContactAsideBody = (onInputChange, filterData, filterText, contactData, setSelectedId) => {
  return (
    <SimpleBar className="nk-chat-aside-body">
      <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <Icon name="search"></Icon>
            </div>
            <input
              type="text"
              className="form-round form-control"
              id="default-03"
              placeholder="Search by name or phone"
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
      </div>
      <div className="nk-chat-aside-panel nk-chat-contact">
        {filterData.length === 0 ? (
          filterText ? (
            <div className="ms-5">No user</div>
          ) : (
            contactData.map((item, idx) => {
              return <ContactItem key={idx} item={item}></ContactItem>;
            })
          )
        ) : (
          <ul className="contacts-list">
            {filterData.map((contact, idx) => {
              return (
                <React.Fragment>
                  <li key={idx} setSelectedId={setSelectedId(contact.id)}>
                    <div className="user-card">
                      <a href="#name" onClick={(ev) => ev.preventDefault()}>
                        <UserAvatar
                          text={findUpper(contact.name)}
                          theme={contact.theme}
                          image={contact.image}
                        ></UserAvatar>
                        <div className="user-name">{contact.name}</div>
                      </a>
                      <div className="user-actions">
                        <a href="#start-chat" onClick={(ev) => ev.preventDefault()}>
                          Start Chat
                        </a>
                      </div>
                    </div>
                  </li>
                </React.Fragment>
              );
            })}
          </ul>
        )}
      </div>
    </SimpleBar>
  );
};
