import React, { useState, useEffect } from 'react';
import { Input, Card, Col, Row, CardTitle, Button } from 'reactstrap';
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import SubscriptionsPricing from './SubscriptionsPricing';
import PricingExplenationCard from './components/PricingExplenationCard';
import { PreviewCard } from '../../components/Component';
import { Select } from 'antd';
import CurrencySelect from './components/CurrencySelect';
import { Switch } from 'antd';
import { Icon } from '../../components/Component';
import Cookies from "js-cookie"
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CryptoPaymentModal from './components/CryptoPaymentModal';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router';
import './styles/Payment.css'
import { useRef } from 'react';
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
const PricingPage = () => {
  const token = Cookies.get('token');
  const [query, setQuery] = useState("");
  const navigate = useNavigate()
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [amount, setAmount] = useState('');
  const [isCrypto, setIsCrypto] = useState(false); // State to track payment type
  const [selectedCurrency, setSelectedCurrency] = useState("")
  const { width } = useWindowSize();
  const [cryptoPaymentModalShown, setCryptoPaymentModalShown] = useState(false);
  const [amountInChosenCrypto, setAmountInChosenCrypto] = useState(0)
  const [walletAddress, setWalletAddress] = useState("")
  const [isBuyModalVisible, setIsBuyModalVisible] = useState(false);
  const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);
  const myTargetRef = useRef(null);
  const [userBalance,setUserBalance] = useState({})

  const isMobile = width < 768; // Set your breakpoint for mobile layout
  const showErrorModal = (msg) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: msg,
    });
  };

  // Example usage
 
  const calculateSMSPrice = (country) => {
    if (!country.has_sender && (!country.twilio_price || !country.sms_buy_price))
      return null;

    if (country.has_sender)
      return parseFloat(country.sms_buy_price)
    else
      return parseFloat(country.twilio_price)
  }

  const buyCredits = async (amount, currency) => {

    if (amount < 2) {
      showErrorModal("Minimum purchase amount is 2$");

      return;
    }

    try {
      const url = `${global.SERVIP}/payment/buy-credits`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        },
        body: JSON.stringify({ amount, currency })
      });
      if (!response.ok)
        return;
      const data = await response.json();
      if (!data.ok)
        return;

      window.location.assign(data.url);
    }
    catch (e) {
      console.log(e);
    }
  }
  async function buyCreditsCrypto(amountInUsd, crypto) {
    if (amount < 20 && isCrypto) {
      showErrorModal("Minimum purchase amount for crypto payments is 20$");

      return;
    }
    const url = `${global.SERVIP}/payment/buy-credits-crypto`; // Adjust the domain/port as necessary
    const payload = {
      amount: amountInUsd,
      crypto: crypto
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Assuming the token is passed directly
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setAmountInChosenCrypto(data.sendTransactionResponse.data.amountInCrypto / 100000000)
      setWalletAddress(data.sendTransactionResponse.data.wallet)
      setCryptoPaymentModalShown(true)

      console.log("Response from server:", data.sendTransactionResponse.data);
    } catch (error) {
      console.error("Error testing buy-credits-crypto:", error);
    }
  }
  const buyAction = async (req, res) => {
    global.purchaseAmount = amount

    if (isCrypto) {
      await buyCreditsCrypto(amount, selectedCurrency)
    }
    else {
      await buyCredits(amount, selectedCurrency)
    }
  }
  useEffect(() => {

    const fetchCountries = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${global.SERVIP}/countries/get-all`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        data.sort((a, b) => {
          const pa = calculateSMSPrice(a);
          const pb = calculateSMSPrice(b);

          if (!pa && pb)
            return 1;
          if (!pb && pa)
            return -1;


          if (pa != pb)
            return pa - pb;

          if (a.has_sender && !b.has_sender)
            return -1;
          if (b.has_sender && !a.has_sender)
            return 1;

          if (!pa && pb)
            return 1;
          if (!pb && pa)
            return -1;


          if (pa != pb)
            return pa - pb;

          if (!a.has_links && b.has_links)
            return 1
          if (!b.has_links && a.has_links)
            return -1


          if (a.link_price != b.link_price)
            return a.link_price - b.link_price;

          if (!a.has_phone_numbers && b.has_phone_numbers)
            return 1
          if (!b.has_phone_numbers && a.has_phone_numbers)
            return -1

          if (a.phone_number_price != b.phone_number_price)
            return a.phone_number_price - b.phone_number_price;

          if (a.name < b.name)
            return -1;
          if (a.name > b.name)
            return 1;

          return 0;
        }
        );
        setCountries(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchBalance = async () => {
      const url = `${global.SERVIP}/user/get_credits`;
    
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Set the authorization header
          }
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log("Fetched balance Data:", data.creditsData); // Debugging
        setUserBalance(data.creditsData)
        return data;
      } catch (error) {
        console.error('Error fetching messages by month:', error);
        throw error;
      }
    };
    fetchCountries();
    fetchBalance()
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/signup")
    }
    if (isCrypto) {
      setSelectedCurrency('BTC');
    }
    else {
      setSelectedCurrency('USD');
    }
  }, [isCrypto])



  const handleToggle = (checked) => {
    setIsCrypto(checked);
  };

  const handleSearch = (event) => {
    setQuery(event.target.value.toLocaleUpperCase());
  };

  const scrollToSubscriptions = () => {
    if (myTargetRef.current) {
      myTargetRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <>
      <Head title="Dashboard Pricing Page" />

      <Content>
       
        <Row>
          {/* Input is now part of the normal document flow */}

          <Col lg={12}>
            <PreviewCard bordered={false}>
              <SubscriptionsPricing myTargetRef={myTargetRef} />
            </PreviewCard>
          </Col>
        </Row>
      </Content>
      <CryptoPaymentModal
        cryptoPaymentModalShown={cryptoPaymentModalShown}
        setCryptoPaymentModalShown={setCryptoPaymentModalShown}
        amountInChosenCrypto={amountInChosenCrypto}
        walletAddress={walletAddress}
        selectedCurrency={selectedCurrency}
      />
      <Modal isOpen={isBuyModalVisible} toggle={() => setIsBuyModalVisible(false)}>
        <ModalHeader >Purchase Credits</ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }} >
            <div>
              <label htmlFor="amountInput" style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>Amount of Credits</label>
              <Input
                id="amountInput"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder=' 1 Credit = 1$'
              />
            </div>
            <div>
              <label htmlFor="currencySelect" style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>Choose Payment Currency</label>
              <CurrencySelect id="currencySelect" isCrypto={isCrypto} selectedCurrency={selectedCurrency} onChange={setSelectedCurrency} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <label htmlFor="cryptoSwitch" style={{ marginBottom: '0', fontWeight: 'bold' }}>Pay with Bitcoin</label>
              <Icon name={"sign-btc-alt"} />
              <Switch
                id="cryptoSwitch"
                checked={isCrypto}
                onChange={handleToggle}
                style={{
                  backgroundColor: isCrypto ? '#f7931a' : undefined, // Bitcoin's orange color when on
                  borderColor: isCrypto ? '#f7931a' : undefined
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => buyAction(true)}>Confirm Purchase</Button>{' '}
          <Button color="secondary" onClick={() => setIsBuyModalVisible(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

    </>

  );
};

export default PricingPage;
