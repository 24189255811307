import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import truckingImage from '../assets/images/delivery-truck.png';
import salesTeam from '../assets/images/sales-team.png';
import saas from '../assets/images/saas.png';

export default function Home_banner() {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <div id="slider-one" className={`main-slider-area ${loaded ? 'fade-in' : ''}`}>
        <div className="slider-bg">
          <div className="item">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6 order-first order-lg-first">
                  <div className="slider__content">
                    <br />
                    <br />
                    <h1 className="main-title-one">
                      Send Text Messages Reliably <span className="d-xxl-block fw-bold"> For The Best Rates On The Market.</span>
                    </h1>
                    <h2>Like SmartLead.ai, but for SMS</h2>
                    <br/>
                    <p className="aftertitle-p" style={{ color: 'black', fontSize: '1.2em' }}>
                      We allow companies such as yours that rely on SMS communication to scale without paying more. The setup takes no longer than an hour and you can migrate all of your current SMS communication to the new service. We even offer to set everything up for you.
                    </p>

                    <Link className="newButtonLink" to="/signup">Sign Up For Free Now</Link>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 d-flex" style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                  <div className="slider-right text-center" style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '30%' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <h2>Industries That We Serve</h2>
                      <br />
                      <button className="bottone5" onClick={() => navigate('/trucking')} style={buttonStyle}>
                        <img src={truckingImage} style={imageStyle} alt="Trucking And Transportation" />
                        <h3 style={textStyle}>Trucking And Transportation </h3>
                        <br />
                      </button>
                      <button className="bottone5" onClick={() => navigate('/sms-marketing')} style={buttonStyle}>
                        <img src={salesTeam} style={imageStyle} alt="Marketing Bulk SMS outreach" />
                        <h3 style={textStyle}>Marketing Bulk SMS outreach </h3>
                        <br />
                      </button>
                      <button className="bottone5" onClick={() => navigate('/saas')} style={buttonStyle}>
                        <img src={saas} style={imageStyle} alt="Small to Medium sized SAAS" />
                        <h3 style={textStyle}>Small to Medium growing SAAS </h3>
                        <br />
                      </button>
                      <ul className="btn-list2 list-none d-sm-flex align-items-center justify-content-center">
                        <li><Link to="/sms-product-comparasings" className="theme_btn border-btn" style={{ padding: '15px', borderRadius: '15px', marginLeft: '10px' , marginTop:"20px"}}>View Pricing And Feature Comparisons <i className="fal fa-long-arrow-right"></i></Link></li>
                      </ul>
                    </div>
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const buttonStyle = {
  backgroundColor: 'white',
  border: '1px solid #ccc',
  borderRadius: '10px',
  padding: '10px 20px',
  marginBottom: '20px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  width: '100%',
  justifyContent: 'center'
};

const imageStyle = {
  width: '100px',
  height: '100px',
  marginRight: '10px'
};

const textStyle = {
  color: '#2d2d2d',
  fontSize: '1.1em',
  fontWeight: 'bold',
  marginLeft: '10px'
};

