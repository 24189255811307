import React, { useState } from "react";
import LogoLight2x from "../../images/logo2Mobile.png";
import LogoDark2x from "../../images/logo2Mobile.png";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onClick={() => {
        window.location.replace("/");
      }}
      className="logo-link"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer", // Changes cursor to pointer
        transition: "transform 0.3s ease", // Smooth hover effect
        transform: isHovered ? "scale(1.05)" : "scale(1)", // Hover scale effect
      }}
      onMouseEnter={() => setIsHovered(true)} // On hover
      onMouseLeave={() => setIsHovered(false)} // On hover out
    >
      <img className="logo-light logo-img" style={{ width: 100, height: 100 }} src={LogoLight2x} alt="logo" />
      <img className="logo-dark logo-img" src={LogoDark2x} alt="logo" />
      <a
        style={{
          marginRight: "20%",
          fontFamily: "Nunito",
          fontSize: "1.2em",
          fontWeight: "bold",
          color: isHovered ? "black" : "black", // Change color on hover
        }}
      >
        TextLink
      </a>
    </div>
  );
};

export default Logo;
