import User from "../../../images/avatar/b-sm.jpg";
import User2 from "../../../images/avatar/c-sm.jpg";
import User3 from "../../../images/avatar/d-sm.jpg";
import User4 from "../../../images/avatar/a-sm.jpg";

export const chatData = [
  {
    id: 1,
    name: "Illiash Hossain",
    nickname: "",
    theme: "purple",
    chatTheme: "purple",
    fav: true,
    active: "30m",
    date: "Now",
    unread: false,
    archive: false,
    delivered: true,
    convo: [
      {
        id: "chat_1",
        chat: ["Hello!", "I found an issues when try to purchase the product."],
        date: "29 Apr, 2020 4:28 PM",
      },
      {
        id: "chat_2",
        me: true,
        chat: ["Thanks for inform. We just solved the issues. Please check now."],
        date: "29 Apr, 2020 4:12 PM",
      },
      {
        id: "chat_3",
        chat: ["This is really cool.", "It’s perfect. Thanks for letting me know."],
        date: "29 Apr, 2020 4:28 PM",
      },
      {
        meta: {
          metaID: "meta_1",
          metaText: "12 May, 2020",
        },
      },
      {
        id: "chat_4",
        chat: ["Hey, I am facing problem as i can not login into application. Can you help me to reset my password?"],
        date: "3:49 PM",
      },
      {
        id: "chat_5",
        me: true,
        date: "3:55 PM",
        chat: ["Definately. We are happy to help you."],
      },
      {
        id: "chat_6",
        date: "3:55 PM",
        chat: ["Thank you! Let me know when it done."],
      },
      {
        id: "chat_7",
        date: "3:55 PM",
        me: true,
        now: true,
        chat: [
          "We just reset your account. Please check your email for verification.",
          "Please confirm if your got email",
        ],
      },
    ],
  },
  {
    id: 2,
    name: "Abu Bin Ishtiak",
    nickname: "",
    theme: "blue",
    chatTheme: "purple",
    date: "4.39 Am",
    active: "60m",
    unread: true,
    archive: false,
    fav: true,
    delivered: true,
    convo: [
      {
        id: "chat_1",
        chat: ["Hi I am Ishtiak, can you help me with something?"],
        date: "4:49 AM",
      },
      {
        id: "chat_2",
        me: true,
        chat: ["Thanks for inform. We just solved the issues. Please check now."],
        date: "4:12 PM",
      },
      {
        id: "chat_3",
        chat: ["This is really cool.", "It’s perfect. Thanks for letting me know."],
        date: "4:28 PM",
      },
    ],
  },
  {
    id: 3,
    name: "George Philips",
    nickname: "",
    image: User,
    chatTheme: "purple",
    fav: true,
    date: "6 Apr",
    unread: false,
    archive: false,
    active: true,
    delivered: true,
    convo: [
      {
        id: "chat_1",
        chat: ["Have you seens the claim from Rose?"],
        date: "6 Apr",
      },
      {
        id: "chat_3",
        me: true,
        chat: ["No I haven't. I will look into it", "It’s perfect. Thanks for letting me know."],
        date: "4:28 PM",
      },
    ],
  },
  {
    id: 6,
    name: "Softnio Group",
    nickname: "",
    chatGroup: true,
    theme: "purple",
    chatTheme: "purple",
    fav: true,
    unread: false,
    archive: false,
    date: "5 May",
    active: true,
    delivered: "sent",
    user: [
      {
        name: "Abu Bin Ishtiak",
        theme: "purple",
      },
      {
        name: "Marele Jhonson",
        image: User3,
      },
    ],
    convo: [
      {
        id: "chat_1",
        me: true,
        chat: ["I just bought a new computer but i am having some problem"],
        date: "27 Mar",
      },
    ],
  },
];