import * as React from 'react';
import Breadcrumb from "../components/BreadCurmb";
import AccordionOne from "../components/AccordionOne";
import FooterOne from "../footer/FooterOne";
import HeaderOne from "../header/HeaderOne";
import Accordion from 'react-bootstrap/Accordion';

function Faq() {
	return (
		<>
			<HeaderOne />
			<Breadcrumb title="FAQ Page" subtitle="Responsive & functional IT blog" pagetitle="FAQ" />
			<section>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-6">
							<div className="faq-que-list mb-30">
								<AccordionOne />
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="faq-que-list mb-30">
								<Accordion defaultActiveKey="0">
									<Accordion.Item eventKey="0">
										<Accordion.Header>Can I use both API and no-code tools with my own devices?</Accordion.Header>
										<Accordion.Body>
											<p>Yes, you can use both API and no-code tools with your own device plan (subscription).</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="1">
										<Accordion.Header>What are rate limits for "Use Your Own Numbers"?</Accordion.Header>
										<Accordion.Body>
											<p>Rate limits vary depending on your carrier, but generally it's about one message every 8 seconds per SIM card (device).</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="2">
										<Accordion.Header>Do I need a SIM card and a phone to set up my own gateway through TextLink?</Accordion.Header>
										<Accordion.Body>
											<p>Yes, this plan is for people who have a device and SIM in the desired destination (or anywhere else, depending on the carrier costs).</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="3">
										<Accordion.Header>How do I set up my device with TextLink?</Accordion.Header>
										<Accordion.Body>
											<p>After registering, go to the "Use Your Own Devices" tab to start the setup. It only takes 5 minutes.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="4">
										<Accordion.Header>What is the process for sending bulk SMS?</Accordion.Header>
										<Accordion.Body>
											<p>You can use our Bulk SMS API or our no-code bulk SMS tool to send messages to a large number of recipients at once.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="5">
										<Accordion.Header>Can I track the delivery of my messages?</Accordion.Header>
										<Accordion.Body>
											<p>Yes, TextLink provides delivery reports so you can track the status of your messages in real-time.</p>
										</Accordion.Body>
									</Accordion.Item>
									<Accordion.Item eventKey="6">
										<Accordion.Header>How do I troubleshoot issues with my TextLink setup?</Accordion.Header>
										<Accordion.Body>
											<p>We offer 24/7 customer support to help you with any issues or questions you may have.</p>
										</Accordion.Body>
									</Accordion.Item>
									
									
								
								</Accordion>
							</div>
						</div>
					</div>
				</div>
			</section>
			<FooterOne />
		</>
	)
}

export default Faq;
