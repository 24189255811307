
import {BrowserRouter as  Routes, Route} from 'react-router-dom'
import HeaderOne from "../header/HeaderOne"
import * as React from 'react'
import FooterOne from '../footer/FooterOne'
import Breadcrumb from '../components/BreadCurmb'
import AboutSection from '../components/AboutSection'
import WorkProcess from '../components/WorkPorcess'
import OfficeTour from '../components/OfficeTour'
import BrandSlider from '../components/BrandSlider'
export default function About() {
  return (
<> 
    <HeaderOne />
    <Breadcrumb title="About Company" subtitle="Responsive & functional IT design" pagetitle="About"/>
    <AboutSection/>
    <WorkProcess/>
    <OfficeTour/>
    <BrandSlider/>
    <FooterOne/>      
</>
  )
}
