import React from "react";
import { Icon, TooltipComponent } from "../../components/Component";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router";
const BalanceCard = ({userBalance}) => {
const navigate = useNavigate()
  return (
    <React.Fragment>
      <div className="card-title-group align-start pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">Balance</h6>
          <p>They amount of credits you have.</p>
        </div>
        <div className="card-tools">
          <TooltipComponent
            iconClass="card-hint"
            icon="help"
            direction="left"
            id="Tooltip-users"
            text="Regular credits can be used to send SMS, withdrawable ones can be withdrawn to your bank account / PayPal"
          ></TooltipComponent>
        </div>
      </div>
      <div className="analytic-au">
      <div className="analytic-data-group analytic-au-group g-3">
    <div className="analytic-data analytic-au-data">
        <div className="title">Regular TextLink Credits</div>
        <div className="amount">
            {Number(userBalance?.credits ?? 0).toFixed(2)}$
        </div>
    </div>
    <div className="analytic-data analytic-au-data">
        <div className="title">Withdrawable credits</div>
        <div className="amount">
            {Number(userBalance?.withdrawable_credits ?? 0).toFixed(2)}$
        </div>
    </div>
</div>



      
        <div className="chart-label-group">
          <div className="chart-label">30 Jan, 2020</div>
        </div>
      </div>
      <Button color="primary" onClick={() =>{navigate("/dashboard/pricing")}} style={{marginTop:15}}>
                        <Icon name="reports"></Icon>
                        <span>Purchase credits</span>
                      </Button>
    </React.Fragment>
  );
};
export default BalanceCard;
