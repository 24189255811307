import React from 'react';
import HeaderOne from "../header/HeaderOne";
import Home_banner from "../components/Home_banner";
import Home_Service_Section from "../components/Home_Service_Section";
import FooterOne from "../footer/FooterOne";
import HomeAbout from '../components/HomeAbout';
import HomeWorkProcess from '../components/HomeWorkPorcess';
import BrandSection from '../components/BrandSliderOne';
import HomePortfolio from '../components/HomePortfolio';
import FeaturesOne from '../components/FeaturesOne';
import TestimonialOne from '../components/TestimonialOne';
import BlogHome from '../components/BlogHome';
import AccordionOne from "../components/AccordionOne"
import Accordion from 'react-bootstrap/Accordion';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router';
import HomeAbout2 from '../components/HomeAbout2';
import HomeAbout3 from '../components/HomeAbout3';
export default function Home() {
	const navigate = useNavigate()
  	return (
	<>
	
	<HeaderOne />	
	<main>		
		<Home_banner />		
		<HomeAbout/>		
		<HomeAbout3/>
		<HomeAbout2/>
		<Home_Service_Section />		
		<TestimonialOne/>
		<div style={{display:'flex', justifyContent: 'center', alignItems:'center', width:'100%'}}>
		<div style={{display:'flex', justifyContent: 'center', alignItems:'center', maxWidth:"500px", flexDirection:'column'}}>
			<h3 style={{marginBottom:'10px'}}>FAQ</h3>
		<AccordionOne/>
		<br/>
		<button   color="primary" className='newButtonLink' onClick={()=>{navigate("/faq")}}
                                  size="lg">More questions</button>
								  <br/>
		</div>
		</div>
	</main>
	<FooterOne />
	
</>
  )
}
//		<BrandSection/> 		<HomePortfolio/>	

