import FeaturesTwo from "../../components/FeaturesTwo"
import FooterOne from "../../footer/FooterOne";
import HeaderOne from "../../header/HeaderOne";
import { useNavigate } from "react-router";
import Head from "../../components/Head";
function ServicesOverview()
{
	const navigate = useNavigate()
return(
<>
<Head title="Services overview" />

		<HeaderOne/>
		<div style={{marginTop:'17%'}}></div>
		<section className="core-feature-area pt-120 pt-md-60 pt-xs-60 pb-100 pb-md-30 pb-xs-30 home-4" style={{backgroundColor:'white'}}>
			<div className="container">
				<div className="row" >
					<div className="col-lg-12">
						<div className="section-title text-center mb-55">
							<h2 className="sect-title" style={{color:'black'}}>Types of SMS services that we offer</h2>
							<p className="lh-1 mb-10"style={{color:'black', marginTop:20}}>Service is a gateway through which you send messages, and all tools are available with both of our service types.</p>

						</div>
					</div>
				</div>
				<div className="row gx-0">
					<div className="col-lg-6 col-md-12" onClick={()=>navigate("/textlinkservice")}   style={{ cursor: 'pointer' }}
>
						<div className="core-feature4">
							<div className="core-content">
								
								<div className="core-text" >
									<h3>TextLink service</h3>
									<ul style={{color:'white', fontSize:'1.1em'}}>
										<li>World-wide</li>
										<li>Affordable</li>
										<li>Works instantly, no setup required</li>
									</ul>
									<p>Just sign up and send messages through us, no additional setup required. You can choose the sender country and price varies based on content. Click to find out more.</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-12"onClick={()=>navigate("/selfhostedservice")}   style={{ cursor: 'pointer' }}
>
						<div className="core-feature4">
							<div className="core-content">
								
								<div className="core-text">
									<h3>Use your own numbers/sim cards</h3>
									<ul style={{color:'white', fontSize:'1.1em'}}>
										<li>Depends on your device</li>
										<li>0.00008$ per sms</li>
										<li>5 minute setup and an android phone required</li>
									</ul>
									<p>Set up your own gateway with just your phone, and either use it yourself to send messages, or sell messages through our partner program. Click to find out more.</p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>		<FooterOne/>		
		</>
	)
}


export default ServicesOverview