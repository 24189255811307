import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import AudienceOverview from "../../components/partials/analytics/audience-overview/AudienceOverview";
import ActiveUser from "../../components/partials/analytics/active-user/ActiveUser";
import WebsitePerformance from "../../components/partials/analytics/website-perfomance/WebsitePerfomance";
import TrafficChannel from "../../components/partials/analytics/traffic-channel/Traffic";
import TrafficDougnut from "../../components/partials/analytics/traffic-dougnut/TrafficDoughnut";
import UserMap from "../../components/partials/analytics/user-map/UserMap";
import BrowserUser from "../../components/partials/analytics/browser-users/BrowserUser";
import PageViewer from "../../components/partials/analytics/page-view/PageView";
import SessionDevice from "../../components/partials/analytics/session-devices/SessionDevice";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent
} from "../../components/Component";
import { AudienceLineChart } from "../../components/partials/charts/analytics/AnalyticsCharts";
import SendingSpendingChart from "./SendingSpendingChart";
import Cookies from 'js-cookie';
import EarningsChart from "./EarningsChart";
import BalanceCard from "./BalanceCard";
import SubscriptionsCard from "./SubscriptionsCard";
import { useNavigate } from "react-router";

const AnalyticsHomePage = () => {
  const [sm, updateSm] = useState(false);
  const [messagesByMonth,setMessagesByMonth] = useState([])
  const [earningsPerMonth,setEarningsPerMonth] = useState([])
  const [userBalance,setUserBalance] = useState({})
  const [userSubscriptions,setUserSubscriptions] = useState([])
  const navigate = useNavigate()
  const token = Cookies.get('token'); // Get the token from cookies

  const fetchMessagesByMonth = async () => {
    const url = `${global.SERVIP}/analytics/get_messages_by_month`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setMessagesByMonth(data.messages)
      return data;
    } catch (error) {
      console.error('Error fetching messages by month:', error);
      throw error;
    }
  };
  const fetchEarningsByMonth = async () => {
    const url = `${global.SERVIP}/analytics/get_earnings_by_month`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setEarningsPerMonth(data.earnings)
      return data;
    } catch (error) {
      console.error('Error fetching messages by month:', error);
      throw error;
    }
  };
  const fetchBalance = async () => {
    const url = `${global.SERVIP}/user/get_credits`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setUserBalance(data.creditsData)
      return data;
    } catch (error) {
      console.error('Error fetching messages by month:', error);
      throw error;
    }
  };

  const fetchSubscriptions = async () => {
    const url = `${global.SERVIP}/user/get_subscriptions`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setUserSubscriptions(data.subscriptions)
      return data;
    } catch (error) {
      console.error('Error fetching messages by month:', error);
      throw error;
    }
  };

  const fetchAllData = async () => {
    if(!token)
    {
      navigate("/signup")
    }
    await fetchMessagesByMonth()
    await fetchEarningsByMonth()
    await fetchBalance()
    await fetchSubscriptions()
  }
  useEffect(() => {
    fetchAllData();
  }, []); 
  return (
    <>
      <Head title="Balance and overview" />
      <Content>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Usage Analytics
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Use and spending.</p>
              </BlockDes>
            </BlockHeadContent>
         
          </div>
        </BlockHead>

      <Block>
        <Row className="g-gs">
          <Col lg="6" xxl="6" className="mb-3 mb-md-0">
              <PreviewAltCard className="h-100">
                <SendingSpendingChart messagesByMonth={messagesByMonth}/>
              </PreviewAltCard>
          </Col>
      
      
           
         
            <Col lg="6" xxl="6" className="mb-3 mb-md-0">

              <Card className="card-bordered h-100" >
                <SubscriptionsCard subscriptions={userSubscriptions}  selling={true}/>
              </Card>
            </Col>
          
        </Row>
      </Block>
      </Content>
    </>
  );
};

export default AnalyticsHomePage;
