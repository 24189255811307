import React, { useState } from "react";
import Head from "../../layout/head/Head";
import AuthFooter from "../auth/AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const DashboardPasswordRestart = () => {
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const url = global.SERVIP+'/user/reset-password'; // Adjust with your server URL if necessary

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (data.ok) {
        // Handle success
        responseAlert("Please check your email to reset your password.", true);
      } else {
        // Handle failure
        responseAlert(data.message, false);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      responseAlert('Error resetting password', false);
    }
  };
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    // Code to send the email
    // After sending the email, set emailSubmitted to true
    setEmailSubmitted(true);
  };

  const handleCodeVerification = async (e) => {
    e.preventDefault();
    // Code to verify the verification code
  };
  const responseAlert = (message, responseOk) => {
    Swal.fire({
      icon: responseOk ? "success" : "error",
      title: responseOk ? "Reset Email Sent" : "Error",
      text: message,
      focusConfirm: false,
    });
  };

  return (
    <>
      <Head title="Reset Password" />
      <div style={{ height: '50px' }}/>
      <Block className="nk-block-middle nk-auth-body wide-xs">
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">Reset Password</BlockTitle>
              <BlockDes>
                <p>{emailSubmitted ? "Enter the verification code sent to your email." : "Enter your email address to receive a password reset link."}</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form onSubmit={emailSubmitted ? handleCodeVerification : handleEmailSubmit}>
            {!emailSubmitted ? (
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="email">Email</label>
                </div>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            ) : (
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="verification-code">Verification Code</label>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="verification-code"
                  placeholder="Enter verification code"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                />
              </div>
            )}
            <div className="form-group">
              <Button color="primary" size="md" outline={true} className="btn-block" type="submit">
                {emailSubmitted ? "Verify Code" : "Send Reset Link"}
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <strong>Back</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
    </>
  );
};

export default DashboardPasswordRestart;
