import React, { useEffect, useState } from 'react';
import { Button, Input, FormGroup, Label, Form } from 'reactstrap';
import { useRef } from 'react';
import Cookies from "js-cookie"

const CreateMessageComponent = ({ fields, firstContactValues, contactCountries, setMessagePrice, setTemplateText }) => {
    const [template, setTemplate] = useState("");
    const [history, setHistory] = useState([template]);
    const [historyIndex, setHistoryIndex] = useState(0);
    const [pricing, setPricing] = useState({});
    const templateRef = useRef(null);

    useEffect(() => {
        setTemplateText(template);
    }, [template])

    const fetchCountries = async () => {
        try {
            const response = await fetch(`${global.SERVIP}/countries/get-all`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            let countries = data;
            let pricing = {};
            for (let country of countries) {
                pricing[country.iso2] = country.sms_buy_price;
            }
            setPricing(pricing);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchCountries()
    }, []);

    useEffect(() => {
        const smsCount = Math.ceil(template.length / 115);
        let sum = 0;
        for (let iso2 in contactCountries)
            if (pricing[iso2])
                sum += contactCountries[iso2] * pricing[iso2];
        sum *= smsCount;
        setMessagePrice(sum);
    }, [contactCountries, template, pricing])

    const insertFieldAtCursor = (field) => {
        const cursorPosition = templateRef.current.selectionStart;
        const textBeforeCursor = template.slice(0, cursorPosition);
        const textAfterCursor = template.slice(cursorPosition);
        const updatedTemplate = textBeforeCursor + `{${field}}` + textAfterCursor;

        updateTemplate(updatedTemplate);
    };

    const updateTemplate = (newTemplate) => {
        const newHistory = history.slice(0, historyIndex + 1);
        newHistory.push(newTemplate);
        setHistory(newHistory);
        setHistoryIndex(newHistory.length - 1);
        setTemplate(newTemplate);
    };

    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') {
            if (historyIndex > 0) {
                setTemplate(history[historyIndex - 1]);
                setHistoryIndex(historyIndex - 1);
            }
            e.preventDefault();
        }
    };

    const generateMessageForRecipient = (recipient) => {
        if (!recipient)
            return "You need to add contacts to group in order to be able to see the preview."
        let message = template;
        Object.keys(recipient).forEach(key => {
            message = message.replace(new RegExp(`{${key}}`, 'g'), recipient[key]);
        });
        return message;
    };

    return (
        <div style={{ padding: "20px" }}>
            <Form>
                <h5 for="messageTemplate">Message Template</h5>
                <br />
                {fields.map(e => <Button className="btn-dim" outline color="primary" onClick={() => insertFieldAtCursor(e)} style={{ marginRight: 5 }}>Insert {e}</Button>)}
                <br /><br />
                <FormGroup>
                    <Input
                        placeholder='Hello {name}, your order to {address} has been delivered'
                        type="textarea"
                        name="text"
                        id="messageTemplate"
                        innerRef={templateRef}
                        value={template}
                        onChange={(e) => updateTemplate(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{ height: "200px", marginBottom: "10px" }}
                    />
                </FormGroup>
            </Form>
            <div>
                <h5>Generated Message Preview</h5>
                <p>{generateMessageForRecipient(firstContactValues)}</p>
            </div>
        </div>
    );
};
export default CreateMessageComponent;
