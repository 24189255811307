import CountUp from 'react-countup';
export default function CounterUpTwo(){
    return(
        <>
            <section className="counter-area theme-gradient-bg1 pt-70 pb-45 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-3 col-md-6">
							<div className="count-text4 text-center mb-30">
								<h3 className="text-white"><span className="counter"><CountUp end={6847} /></span></h3>
								<p className="fs-14 text-white">Successful projects</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="count-text4 count-line text-center mb-30">
								<h3 className="text-white"><span className="counter"><CountUp end={2500} /></span></h3>
								<p className="fs-14 text-white">Active Clients</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="count-text4 text-center mb-30">
								<h3 className="text-white"><span className="counter"><CountUp end={100} /></span></h3>
								<p className="fs-14 text-white">Worldwide Base</p>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="count-text4 count-line before-none text-center mb-30">
								<h3 className="text-white"><span className="counter"><CountUp end={3200} /></span></h3>
								<p className="fs-14 text-white">Worldwide Base</p>
							</div>
						</div>
					</div>
				</div>
			</section>
        </>
    )
}