import { Link } from "react-router-dom"

export default function OfficeTour(){
    return(
        <section className="about-video-area pb-90  pb-md-30  pb-xs-30">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-6">
						<div className="about-video-wrapper2 mb-30 pe-xxl-5">
							<Link to="https://www.youtube.com/watch?v=lTxn2BuqyzU" className="popup-video"><i className="fas fa-play"></i></Link>
							<img className="w-100" src="assets/img/about/about-9.jpg" alt="About Img" />
						</div>
					</div>
					<div className="col-lg-6">
						<div className="about-video-text mb-30">
							<h6 className="semi-title ps-0 mb-15">Office Tour</h6>
							<h2 className="sect-title lh-1 mb-35">We create a culture
								that inspires us</h2>
							<p className="mb-45 paragraph-title">Help our clients succeed by creating brand identities,
								digital experiences,
								and print materials. We offer a wide range of Courses For all ages &amp; Levels.
								We offer a wide range of Courses For all ages and Levels.</p>
							<div className="d-sm-flex align-items-center">
								<img className="mr-30 mb-10" src="assets/img/about/about-5.jpg" alt="image" />
								<img className="mb-10" src="assets/img/about/about-6.jpg" alt="image" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}