import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Button } from 'reactstrap';
import { Icon } from '../../../components/Component';
const PurchaseHistory = () => {
  const [purchases, setPurchases] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPurchases = async () => {
      const data = await getUserPurchases();
      if (data && data.purchases) {
        setPurchases(data.purchases);
      }
    };

    fetchPurchases();
  }, []);
  const getUserPurchases = async () => {
    try {
      const token = Cookies.get('token'); // Retrieve the token from cookies
      if (!token) {
        console.log('No token found');
        return null;
      }
  
      const response = await fetch(global.SERVIP + "/user/get_user_purchases", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Set the Authorization header with the token
        },
      });
  
      if (!response.ok) {
        console.error('Failed to retrieve purchases:', response.status);
        return null;
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error while fetching user purchases:', error);
      return null;
    }
  };
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <h5>Purchase History</h5>
      {
  purchases.length > 0 ? (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ border: '1px solid gray', padding: '8px', textAlign: 'left' }}>Amount (USD)</th>
          <th style={{ border: '1px solid gray', padding: '8px', textAlign: 'left' }}>Date Created</th>
          <th style={{ border: '1px solid gray', padding: '8px', textAlign: 'left' }}>Status</th>
        </tr>
      </thead>
      <tbody>
        {purchases.map((purchase, index) => (
          <tr key={index}>
            <td style={{ border: '1px solid gray', padding: '8px' }}>${parseFloat(purchase.amount).toFixed(2)}</td>
            <td style={{ border: '1px solid gray', padding: '8px' }}>{new Date(purchase.createdAt).toLocaleDateString()}</td>
            <td style={{ border: '1px solid gray', padding: '8px' }}>{purchase.status}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p>No purchases yet.</p>
  )
}


      <br/>
      <Button className="btn-dim" color="primary" onClick={() => navigate('/dashboard/pricing')}  style={{padding:5, width:'50%',display:'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
      <Icon name="invest" style={{marginRight:5}}/>
        Purchase Subscriptions</Button>
    </div>
  );
};

export default PurchaseHistory;
