import { Link } from 'react-router-dom';
export default function ServicesMenu(){
    return(
        <>
        <h6 className="widget-title text-black pb-15 mb-35">Our Services</h6>
        <ul className="fot-list">
            <li><Link to="/smstoolsoverview" style={{color:'black'}}>Services Overview</Link></li>
            <li><Link to="/smsapi" style={{color:'black'}}>SMS API</Link></li>
            <li><Link to="/bulksms" style={{color:'black'}}>No-code bulk SMS</Link></li>
            <li><Link to="/web-sms-app" style={{color:'black'}}>SMS App</Link></li>
        </ul>
        </>
        
    )
}