import React, { useState } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

const VerifySubuser = () => {
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [error, setError] = useState('');

  const handleCreateAccount = async (values) => {
    if (values.password !== values.repeatPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      const response = await fetch(`${global.SERVIP}/verify-subuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, token, password: values.password }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.ok) {
        await Swal.fire({
          icon: 'success',
          title: 'Account Created',
          text: 'Your account has been successfully created.',
          focusConfirm: false,
        });
        Cookies.set('token', data.token, { expires: 15, path: '/' });
        Cookies.set('subuser', true, { expires: 15, path: '/' });

        navigate('/dashboard/chat'); // Redirect to chat

      } else {
        setError(data.message);
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div style={styles.authWrapper}>
     
      <div style={styles.authContents}>
        <Form
          name="verifySubuser"
          form={form}
          onFinish={handleCreateAccount}
          layout="vertical"
        >
          <h3 style={styles.title}>Set Your Password To Complete Registration</h3>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            label="Password"
          >
            <Input.Password style={styles.input} />
          </Form.Item>

          <Form.Item
            name="repeatPassword"
            rules={[{ required: true, message: 'Please repeat your password!' }]}
            label="Repeat Password"
          >
            <Input.Password style={styles.input} />
          </Form.Item>

          {error && <div style={styles.error}>{error}</div>}

          <Form.Item>
            <Button type="primary" htmlType="submit" style={styles.button}>
              Create Account
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const styles = {
  authWrapper: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '1rem',
    textAlign: 'center',
  },
  authNotice: {
    marginBottom: '20px',
  },
  authContents: {
    background: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  title: {
    marginBottom: '30px',
  },
  input: {
    padding: '10px',
  },
  error: {
    color: 'red',
    marginBottom: '16px',
  },
  button: {
    width: '100%',
    height: '40px',
  },
};

export default VerifySubuser;
