const menu = [
  {
    icon: "send",
    text: "Test services",
    active: false,
    link: "/dashboard/test",
    keyWords: ["test", "api", "send", "send sms"],
    description: "Send message now and test services",
    admin:true
  },
  {
    icon: "chat",
    text: "Chat App",
    link: "/dashboard/chat",
    active: "false",
    keyWords: ["chat", "sms"],
    description: "See the messages that you have sent in the chat-like interface.",
    admin:false
  },
  {
    icon: "users",
    text: "Team",
    link: "/dashboard/team",
    active: "false",
    keyWords: ["team", "account"],
    description: "Manage your team members.",
    admin:true

  },
  {
    icon: "line-chart-up",
    text: "Balance And Analytics",
    active: false,
    link: "/dashboard/analytics",
    keyWords: ["analytics", "data", "overview", "statistics", "usage"],
    description: "Analyze your service usage and performance statistics.",
    admin:true

  },
  {
    icon: "coin-alt",
    text: "Pricing",
    link: "/dashboard/pricing",
    active: "false",
    keyWords: ["purchase", "pricing", "plans", "cost", "subscription", "buy sms", "purchases", "prices", "buy", "subscriptions"],
    description: "Explore various pricing plans and choose the best fit for your needs.",
    admin:true

  },

  {
    icon: "network",
    text: "API",
    link: "/dashboard/api",
    active: false,
    keyWords: ["api", "integration", "development", "code", "programming", "test", "react", "java", "js", "python", "c#"],
    description: "Integrate our services with your system using our robust API.",
    admin:true


  },
  {
    icon: "comments",
    text: "No-Code Bulk SMS",
    active: false,
    subMenu: [
      {
        text: "Dashboard",
        link: "/dashboard/bulksms-dashboard",
        keyWords: ["bulk sms", "send in bulk", "no code", "no-code", "zero code", "bulk", "campaign"],
        description: "Check you Bulk SMS campaigns activity."
      },
      {
        text: "Create campaign",
        link: "/dashboard/bulksms-create",
        keyWords: ["bulk sms", "send in bulk", "no code", "no-code", "zero code", "bulk", "campaign", "create", "start", "new"],
        description: "Create a new Bulk SMS campaign."
      },



    ],
    admin:false


  },

  {
    icon: "mobile",
    text: "Devices And Setup",
    active: false,
    subMenu: [
      {
        text: "Tutorial",
        link: "/dashboard/your-devices/get-started",
        keyWords: ["devices", "mobile", "personal", "use", "connectivity", "free", "setup", "get started"],
        description: "Leverage your personal devices for enhanced messaging services. See how to set ut up."
      },
      {
        text: "Dashboard",
        link: "/dashboard/your-devices/overview",
        keyWords: ["devices", "mobile", "personal", "use", "connectivity", "free"],
        description: "Overview of your devices and their SIM cards."
      },],
      admin:true

  },

  {
    heading: "Account",
    admin:false
  },
  {
    icon: "text-rich",
    text: "Account page",
    link: "/dashboard/user",
    active: "false",
    keyWords: ["account", "profile", "settings", "user", "details"],
    description: "Access and manage your account details and settings.",
    admin:false
  },
  {
    icon: "shield",
    text: "Change password",
    link: "/dashboard/change-password",
    active: "false",
    keyWords: ["change", "password", "security", "update", "account"],
    description: "Update your password regularly to maintain account security.",
    admin:false

  },

];
export default menu;
