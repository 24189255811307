import React, { useState } from 'react';
import { Collapse, Card, CardBody, Button } from 'reactstrap'; // Ensure Button is imported
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
function PricingExplanationCard({buyAction,scrollToSubscriptions}) {
    const [openSection, setOpenSection] = useState(null);
const navigate = useNavigate()
    const toggleSection = section => {
        setOpenSection(openSection === section ? null : section);
    };

    return (
        <Card className="card h-100">
            <CardBody className="card-inner">
                <div className="accordion accordion-flush" id="pricingAccordion">
                    {/* Credits Section */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingCredits">
                            <button className="accordion-button" type="button" onClick={() => toggleSection('credits')}>
                                Credits
                            </button>
                        </h2>
                        <Collapse isOpen={openSection === 'credits'}>
                            <div className="accordion-body">
                                <p>
                                    When you purchase credits, you don't need any additional setup, you can use all of our API's and Tools immediately.
                                </p>
                                <Button size="sm" color="primary" style={{ marginTop: '10px' }} onClick={() => buyAction(true)}>Buy Credits</Button> {/* Small and elegant button */}
                            </div>
                        </Collapse>
                    </div>

                    {/* Subscriptions Section */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSubscriptions">
                            <button className="accordion-button" type="button" onClick={() => toggleSection('subscriptions')}>
                                Subscriptions
                            </button>
                        </h2>
                        <Collapse isOpen={openSection === 'subscriptions'}>
                            <div className="accordion-body">
                                <p>
                                    For subscriptions, you need to set up your own service with your phone and SIM/SIM's. After that is done you can use all of our API's and Tools. Check out how it works <Link style={{color:'blue'}} to="/dashboard/your-devices/get-started">HERE</Link>
                                </p>
                                <Button size="sm" color="primary" style={{ marginTop: '10px' }} onClick={() =>scrollToSubscriptions()}>Buy a Subscription</Button> {/* Small and elegant button */}
                            </div>
                        </Collapse>
                    </div>

                    {/* Payment Options Section */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingPaymentOptions">
                            <button className="accordion-button" type="button" onClick={() => toggleSection('paymentOptions')}>
                                Payment options
                            </button>
                        </h2>
                        <Collapse isOpen={openSection === 'paymentOptions'}>
                            <div className="accordion-body">
                                <p>
                                    You can choose the currency you want to pay in. You can also pay with crypto which gives you a 5% more credits, you can either choose fiat currency or crypto in the bottom right corner of the credits tab. Minimum for crypto payments is 20$ worth of credits. We currently only support automatic payments with bitcoin, if you want to purchase our services with other cryptocurrencies, contact us at sales@textlinksms.com
                                </p>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default PricingExplanationCard;
