import { Link } from 'react-router-dom';
import HeaderOne from "../../header/HeaderOne";
import * as React from 'react';
import FooterOne from '../../footer/FooterOne';
import Head from '../../components/Head';

function SAAS() {
  return (
    <>
      <Head title="TextLink for SAAS Businesses" />
      <HeaderOne />

      <section className="about-us-area pt-145 pb-90 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
        <div className="container" style={{ marginTop: '10%' }}>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-text-wrapper2 mb-30 pr-75">
                <h6 className="semi-title semi-title2">SMS For SAAS Companies</h6>
                <h2 className="sect-title text-heding2 mb-35">The Perfect Messaging Tool for Small and Medium SAAS Companies</h2>
                <p className="paragraph-title">
                  TextLink offers a comprehensive set of tools to enhance your SAAS business communication. Our solutions are designed to help you verify users, send notifications, and run marketing campaigns effortlessly.
                  <br />
                  <br />
                  Whether you need to send transactional messages, promotional campaigns, or verification codes, TextLink provides robust and scalable APIs that integrate seamlessly with your applications.
                </p>
                <ul className="text-list text-list2 list-none border-bottom" style={{ marginTop: 20 }}>
                  <li>
                    <div className="services-list">
                      <h5 className="fs-20 text-heding2">Verification API</h5>
                      <p>Verify user phone numbers with ease. We handle all the logic and return a verification code.</p>
                    </div>
                  </li>
                  <li>
                    <div className="services-list">
                      <h5 className="fs-20 text-heding2">Transactional Messaging</h5>
                      <p>Send important notifications and updates to your users in real-time.</p>
                    </div>
                  </li>
                  <li>
                    <div className="services-list">
                      <h5 className="fs-20 text-heding2">Marketing Campaigns</h5>
                      <p>Reach out to your prospects with targeted SMS marketing campaigns.</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img-wrapper mb-30">
                <img
                  className="img-one img-fluid d-xxl-inline-block rounded-3"
                  src="assets/img/about/saas.png"
                  alt="SAAS Tools"
                  style={{ cursor: 'pointer', transition: 'transform 0.3s ease' }}
                  onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.05)'}
                  onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <ul className="btn-list2 list-none d-sm-flex align-items-center justify-content-center">
            <li><Link to="/faq" className="theme_btn mr-20">FAQ</Link></li>
            <li><Link to="/signup" className="theme_btn border-btn">Start now <i className="fal fa-long-arrow-right"></i></Link></li>
          </ul>
        </div>
      </section>
      <br />
      <FooterOne />
    </>
  );
}

export default SAAS;
