import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation
import './styles/PaymentSuccess.css'; // Import the CSS for styling
import { Button } from 'reactstrap';
import { trackPurchase } from '../../../AnalyticsEvents/MetaPixelEvents';
const PaymentSuccess = () => {

  const navigate = useNavigate();
  
  useEffect(() => {
    trackPurchase(global.purchaseAmount);
  }, []);
  
  const goToDashboard = () => {
    navigate('/dashboard/analytics'); // Navigate to your dashboard route
  };

  return (
    <div className="payment-success-container">
<div style={{width:200,height:200, marginBottom:20}}>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><rect width="55" height="39" x="9" y="21" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" rx="6" ry="6"></rect><path fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 44L25 44"></path><path fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M30 44L38 44"></path><path fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M42 44L50 44"></path><path fill="none" stroke="#c4cefe" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 50L36 50"></path><rect width="15" height="8" x="16" y="31" fill="#c4cefe" rx="1" ry="1"></rect><path fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M76.79 72.87L32.22 86.73a6 6 0 01-7.47-4L17 57.71a6 6 0 014-7.51l44.52-13.86a6 6 0 017.48 4l7.73 25.06a6 6 0 01-3.94 7.47z"></path><path fill="#6576ff" d="M75.27 47.3L19.28 64.71 17.14 57.76 73.12 40.35 75.27 47.3z"></path><path fill="#c4cefe" d="M30 77.65l-1.9-6.79a1 1 0 01.69-1.23l4.59-1.3a1 1 0 011.23.7l1.9 6.78a1 1 0 01-.67 1.19l-4.59 1.3a1 1 0 01-1.25-.65zM41.23 74.48l-1.9-6.78a1 1 0 01.67-1.23l4.58-1.3a1 1 0 011.23.69l1.9 6.78a1 1 0 01-.71 1.24l-4.58 1.29a1 1 0 01-1.19-.69zM52.43 71.32l-1.9-6.79a1 1 0 01.69-1.23l4.59-1.3a1 1 0 011.19.7l1.9 6.78a1 1 0 01-.69 1.23L53.66 72a1 1 0 01-1.23-.68z"></path><ellipse cx="55.46" cy="19.1" fill="#fff" stroke="#6576ff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" rx="16.04" ry="16.1"></ellipse><ellipse cx="55.46" cy="19.1" fill="#e3e7fe" rx="12.11" ry="12.16"></ellipse><text fill="#6576ff" font-family="Nunito-Black, Nunito Black" font-size="16.12" transform="matrix(.99 0 0 1 50.7 23.72)">$</text></svg>     
</div>
<h1>Your payment has been received.</h1>
      <Button onClick={()=>{goToDashboard()}} className="btn-dim" outline color="success">Go Back to Dashboard</Button>
    </div>
  );
};

export default PaymentSuccess;
