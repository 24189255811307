import React, { useState, useEffect } from "react";
import { Icon } from "../../../../components/Component";
import { CardTitle } from "reactstrap";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
const BarChart = ({ chartData }) => {
  return (
    <Bar
      className="sales-bar-chart chartjs-render-monitor"
      data={chartData}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: '11px',
            },
            titleColor: "#6783b8",
            titleMarginBottom: 4,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: '10px',
            },
            bodySpacing: 3,
            padding: 8,
            footerMarginTop: 0,
          },
        },
        scales: {
          y: {
            display: true,
            beginAtZero: true,
            grid: {
              display: false
            }
          },
          x: {
            display: true,
            grid: {
              display: false
            }
          },
        },
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10
          }
        }
      }}
    />
  );
};


const EarningsChart = ({ earningsData }) => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (earningsData && earningsData.length > 0) {
      const labels = earningsData.map(item => item.month);
      const earnings = earningsData.map(item => parseFloat(item.totalEarnings));
      
      const data = {
        labels: labels,
        datasets: [
          {
            label: 'Earnings ($)',
            data: earnings,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          }
        ]
      };

      setChartData(data);
    }
  }, [earningsData]);

  if(!earningsData)
  {
    return(
      <a>No data available</a>
    )
  }
  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-3">
        <CardTitle className="card-title">
          <h6 className="title">Earnings Overview</h6>
          <p>
            Overview of your earnings.{" "}
            <Link to={`${process.env.PUBLIC_URL}/detailed-stats`} className="link link-sm">
              Detailed Stats
            </Link>
          </p>
        </CardTitle>
        {/* Dropdown and other UI elements */}
      </div>
      <div className="nk-order-ovwg">
        <Row className="g-4 align-end">
          <Col lg="12">
            <div className="nk-order-ovwg-ck">
            <div className="nk-order-ovwg-ck">
            {(chartData && chartData.datasets)? <BarChart chartData={chartData} /> :<p>No earnings data available.</p>}
</div>
            </div>
          </Col>
          {/* Additional UI elements */}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EarningsChart;
