import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Default styles
import './serviceItem.css'; // Assuming this contains your custom styles

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

export default function HomeBanner() {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(null);

  const itemStyle = {
    cursor: 'pointer',
    transition: 'transform 0.6s ease',
    margin: '5px',
    height: '100%'
  };

  const hoveredItemStyle = {
    ...itemStyle,
    transform: 'scale(1.05)',
  };


  // Each service item data including link, icon, title, and features
  const serviceItems = [
    {
      link: "/pricing",
      icon: "assets/img/icon/low-price.png",
      title: "Competitive pricing",
      features: ["Lower prices than Twilio", "Flexible plans", "No hidden costs"],
    },
    {
      link: "/web-sms-app",
      icon: "assets/img/icon/customer-support.png",
      title: "Web Text Messaging Dispatch App",
      features: ["Import from excel", "Customize", "Predictable pricing"],
    },
    {
      link: "/bulksms",
      icon: "assets/img/icon/web-development.png",
      title: "No-code sms tools",
      features: ["Import from excel", "Customize", "Predictable pricing"],
    },
    {
      link: "/smsapi",
      icon: "assets/img/icon/api.png",
      title: "Simple API's",
      features: ["Package for every major programming language", "Send and recieve SMS","Get started in minutes" ],
    },
    {
      link: "/selfhostedservice",
      icon: "assets/img/icon/data-cloud.png",
      title: "Simple Self Hosted Service",
      features: ["Monthly subscription", "Send as much as you want", "From 0.0008$ per SMS"],
    },
    
  ];

  return (
    <>    
<section id="services" className="grey-bg services-area pt-120 pb-70 pt-md-60 pt-xs-60 pb-xs-60">
        <div className="container" style={{display:'flex', justifyContent: 'center', alignItems: 'center', marginBottom:"50px"}}>
        <h2>In case you missed something ...</h2>
        </div>
        <div className="custom-container-1">
          <div className="services-slide">
		  <Carousel 
  responsive={responsive}
  ssr // Server-side rendering.
  infinite
  autoPlaySpeed={1000}
  keyBoardControl
  customTransition="transform 500ms ease-in-out"
  transitionDuration={500}
  containerClass="carousel-container"
  deviceType={responsive}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>

              {serviceItems.map((item, index) => (
                <div
                  key={index}
                  className="item item23"
                  onClick={() => navigate(item.link)}
                  style={hoverIndex === index ? hoveredItemStyle : itemStyle}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                >
                  <div className="single-services" style={{    height: '100%',}}>
                    <div className="s-icon">
                      <img src={item.icon} alt="Services icon" />
                    </div>
                    <h4 className="sub-title">{item.title}</h4>
					<ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
  {item.features.map((feature, featureIndex) => (
    <li key={featureIndex} style={{ padding: '5px 0', color: '#666' }}>
      {feature}
    </li>
  ))}
</ul>

                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </section>
    </>
  );
}
