import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Privacy Policy</h2>
      <p style={styles.text}>
        Your privacy is important to us. It is TextLink's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including via our app, TextLink, and its associated services.
      </p>
      <p style={styles.text}>
        Personal information is any information about you which can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use an app or online service.
      </p>
      <p style={styles.text}>
        In the event our app contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our app.
      </p>
      <p style={styles.text}>
        This policy is effective as of 2 August 2023.
      </p>
      <p style={styles.text}>
        Last updated: 2 August 2023
      </p>

      <h3 style={styles.subHeader}>Information We Collect</h3>
      <p style={styles.text}>
        Information we collect falls into one of two categories: "voluntarily provided" information and "automatically collected" information.
      </p>
      <p style={styles.text}>
        "Voluntarily provided" information refers to any information you knowingly and actively provide us when using our app and its associated services.
      </p>
      <p style={styles.text}>
        "Automatically collected" information refers to any information automatically sent by your device in the course of accessing our app and its associated services.
      </p>

      <h4 style={styles.subHeader}>Log Data</h4>
      <p style={styles.text}>
        When you access our servers via our app, we may automatically log the standard data provided by your device. It may include your device's Internet Protocol (IP) address, your device type and version, your activity within the app, time and date, and other details about your usage.
      </p>
      <p style={styles.text}>
        Additionally, when you encounter certain errors while using the app, we automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is.
      </p>
      <p style={styles.text}>
        Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.
      </p>

      <h4 style={styles.subHeader}>Device Data</h4>
      <p style={styles.text}>
        Our app may access and collect data via your device's in-built tools, such as:
      </p>
      <ul style={styles.list}>
        <li>Phone/SMS</li>
        <li>Notifications</li>
      </ul>
      <p style={styles.text}>
        When you install the app or use your device’s tools within the app, we request permission to access this information. The specific data we collect can depend on the individual settings of your device and the permissions you grant when you install and use the app.
      </p>

      <h4 style={styles.subHeader}>Personal Information</h4>
      <p style={styles.text}>
        We may ask for personal information — for example, when you subscribe to our newsletter or when you contact us — which may include one or more of the following:
      </p>
      <ul style={styles.list}>
        <li>Email</li>
      </ul>

      {/* Additional Privacy Policy details omitted for brevity but follow the same pattern as above */}

      <h3 style={styles.subHeader}>Contact Us</h3>
      <p style={styles.text}>
        For any questions or concerns regarding your privacy, you may contact us using the following details:
      </p>
      <p style={styles.text}>
        <strong>Aleksandar Spremo</strong><br />
        support@textlinksms.com
      </p>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    lineHeight: '1.6',
    color: '#333',
    backgroundColor: '#fff',
  },
  header: {
    color: '#397EB6',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  subHeader: {
    fontWeight: 'bold',
    color: '#397EB6',
    fontSize: '20px',
    marginTop: '20px',
    marginBottom: '10px',
  },
  text: {
    marginBottom: '15px',
    fontSize: '16px',
  },
  list: {
    listStyleType: 'circle',
    marginLeft: '20px',
    marginBottom: '15px',
  },
};

export default PrivacyPolicy;
