import { Link } from "react-router-dom";
import Breadcrumb from "../../components/BreadCurmb";
import FooterOne from "../../footer/FooterOne";
import HeaderOne from "../../header/HeaderOne";
import { useNavigate } from "react-router-dom";
import Head from "../../components/Head";

 function TextLinkService(){
	const navigate = useNavigate()
	return(
		<>
		                          <Head title="TextLink Service Overview" />

		<HeaderOne/>
		<Breadcrumb pagetitle={"TextLink service"} subtitle={"Send messages with TextLink, no additional setup requiered"} title={"Use our SMS service"}/>				
		<section className="about-services-area pt-120 pb-130 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="about-services-img mb-30">
							<img className="img-one img-fluid" src="assets/img/services/textlinkServiceImg1.png" alt="Service Img" />
						</div>
					</div>
					<div className="col-lg-6">
						<div className="about-services-text ps-xxl-5 mb-30 pt-30">
							<h3 className="service-title-1 mb-30">Send messages within minutes without any aditional setup</h3>
							<p className="mb-35">If you do not have or want to use your own devices and SIM cards, this option is for you. All you have to do is register and you can send some texts for free, to see wheather we are the right provider for you.
							Just like Twilio or MessageBird.</p>
							<p className="mb-35">
							No additional setup required.</p>	
							<p className="mb-35">
							Try it our for FREE (No credit card required).
							</p>						
							<Link to="/signup" className="theme_btn theme_btn3">Try it our now<i
									className="fal fa-long-arrow-right"></i></Link>
									<br/>
									<Link className="theme_btn theme_btn3" to="/pricing">Check the pricing</Link>
<br/>
									<div className="plan-box d-sm-flex align-items-center" style={{marginTop:30}}>
								<div className="icon d-flex align-items-center">
									<img src="assets/img/icon/s-5a.png" alt="Icon" />
									<h5>Do you have any questions, check the FAQ?</h5>
								</div>
								<Link className="d-block" to="/faq" style={{backgroundColor:'white', padding:20, borderRadius:5}}><i className="fal fa-long-arrow-right"></i></Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section className="grey-bg2 services-tools pt-120 pb-190 pt-md-60 pb-md-60 pt-xs-60 pb-xs-30">
			<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
		<h3 className="service-title-1 mb-30">Tools available with this service</h3>
		</div>
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-md-6" onClick={() =>navigate("/bulksms")}   style={{ cursor: 'pointer' }}>
						<div className="white-bg tool-box text-center mb-30">
							<img src="assets/img/icon/s-6a.png" alt="Icon" />
							<h5 className="fs-20">No-code Bulk SMS</h5>
							<p className="fs-14">Send 1000's of texts without code from our console</p>
							<div className="number">02</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6" onClick={() =>navigate("/smsapi")} style={{ cursor: 'pointer' }}>
						<div className="white-bg tool-box text-center mb-30" >
							<img src="assets/img/icon/s-6b.png" alt="Icon" />
							<h5 className="fs-20">SMS API</h5>
							<p className="fs-14">Use our intuitive API to send any type of message to anyone</p>
							<div className="number">02</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6" onClick={() =>navigate("/verificationapi")} style={{ cursor: 'pointer' }}>
						<div className="white-bg tool-box text-center mb-30">
							<img src="assets/img/icon/s-6c.png" alt="Icon" />
							<h5 className="fs-20">Verification API</h5>
							<p className="fs-14">Verify your users through their phone numbers</p>
							<div className="number">02</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="white-bg tool-box text-center mb-30" onClick={() =>navigate("/bulksmsapi")} style={{ cursor: 'pointer' }}>
							<img src="assets/img/icon/s-6d.png" alt="Icon" />
							<h5 className="fs-20">Bulk SMS API</h5>
							<p className="fs-14">Send SMS in bulk with our API (limits apply)</p>
							<div className="number">02</div>
						</div>
					</div>
				</div>
			</div>
		</section>			
		<FooterOne/>		
		</>
	)
}

export default TextLinkService