import Head from "../../layout/head/Head"
import Content from "../../layout/content/Content"
import { Row, Col, Button, Card, CardBody } from "reactstrap"
import CreditsCard from "./components/CreditsCard"
import AudienceOverview from "../../components/partials/analytics/audience-overview/AudienceOverview"
import TextLinkFAQ from "./components/TextLinkFAQ"
import BalanceCard from "../Analytics/BalanceCard"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import SendingSpendingChart from "../Analytics/SendingSpendingChart"
function TextLinkService(){

  const [messagesByMonth,setMessagesByMonth] = useState([])
  const [userBalance,setUserBalance] = useState({})


  const fetchBalance = async () => {
    const token = Cookies.get('token'); // Get the token from cookies
    const url = `${global.SERVIP}/user/get_credits`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setUserBalance(data.creditsData)
      return data;
    } catch (error) {
      console.error('Error fetching messages by month:', error);
      throw error;
    }
  };
  const fetchMessagesByMonth = async () => {
    const token = Cookies.get('token'); // Get the token from cookies
    const url = `${global.SERVIP}/analytics/get_messages_by_month`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setMessagesByMonth(data.messages)
      return data;
    } catch (error) {
      console.error('Error fetching messages by month:', error);
      throw error;
    }
  };
  const fetchAllData = async () => {
    await fetchMessagesByMonth()
    await fetchBalance()
  }
  useEffect(() => {
    fetchAllData();
  }, []); 
return (
    <>
    <Head title="TextLink Service Dashboard" />
    <Content>
      <h3>TextLink service overview</h3>
      <p style={{margin:10}}>Check your balance and activity</p>
      <Col>
      <Row className="my-3">
        <Col>
        <Card >
          <CardBody>
            <p>By using TextLink service, you don't need anything else, just purchase the credits and thats it.</p>
            <br/>
            <BalanceCard userBalance={userBalance} />
              </CardBody>
              </Card>
              <Card>
          <CardBody>
          <SendingSpendingChart messagesByMonth={messagesByMonth}/>
              </CardBody>
              </Card>
        </Col>

        <Col>
        <Card className="card h-100">
          <CardBody>
          <h5>Our service FAQ</h5>
          <TextLinkFAQ/>
          </CardBody>
          </Card>
        </Col>
      </Row >
      </Col>
    </Content>
  </>
)
}

export default TextLinkService