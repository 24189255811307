import Accordion from 'react-bootstrap/Accordion';

export default function AccordionOne() {
    return (
        <>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>What is your pricing strategy?</Accordion.Header>
                    <Accordion.Body>
                        <p>We offer one pricing plan: Subscriptions, each with a different message limit.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Why self-hosted gateway?</Accordion.Header>
                    <Accordion.Body>
                        <p>Self-setup involves using our APIs and no-code tools with your own device and SIM card, providing cheaper rates.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>What API tools do you offer?</Accordion.Header>
                    <Accordion.Body>
                        <p>We offer a Regular SMS API for sending messages, a Verification API for verifying users by phone number, and a Bulk SMS API for sending large amounts of messages simultaneously.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>What no-code tools do you offer?</Accordion.Header>
                    <Accordion.Body>
                        <p>We offer a bulk message outreach tool that allows you to send custom messages to large groups directly from your browser. It's ideal for promotional messages and user reminders.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>What is “use your own numbers”?</Accordion.Header>
                    <Accordion.Body>
                        <p>“Use your own numbers” allows you to connect your Android device with a SIM card to our service. This setup lets you send messages at very low rates, leveraging your carrier's pricing.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>How do I sign up for TextLink services?</Accordion.Header>
                    <Accordion.Body>
                        <p>You can sign up for TextLink services by visiting our sign-up page and following the registration process.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Can I use TextLink for international messaging?</Accordion.Header>
                    <Accordion.Body>
                        <p>Yes, TextLink supports international messaging, allowing you to send messages to recipients in various countries.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>How secure is my data with TextLink?</Accordion.Header>
                    <Accordion.Body>
                        <p>We prioritize your data security and use industry-standard encryption and security measures to protect your information.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>How do I integrate TextLink’s API into my existing system?</Accordion.Header>
                    <Accordion.Body>
                        <p>Integrating TextLink’s API into your existing system is straightforward. Our documentation provides step-by-step instructions for various programming languages.</p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>Can I schedule messages to be sent at a later time?</Accordion.Header>
                    <Accordion.Body>
                        <p>Yes, TextLink allows you to schedule messages to be sent at a later time, ensuring timely delivery for your campaigns and notifications.</p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}
