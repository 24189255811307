import Head from "../../../layout/head/Head"
import Content from "../../../layout/content/Content"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import OrderActivity from "./components/order-activity/OrderActivity"
import Cookies from "js-cookie"
import { useEffect, useState } from "react"
import DevicesTable from "../../Sell/SellingDashboard/components/DevicesTable"
import { useNavigate } from "react-router"
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap"
import { Icon } from "../../../components/Component"
import SubscriptionsCard from "../../Analytics/SubscriptionsCard"

function YourDevicesService() {
  const [modalShown, setModalShown] = useState(false);
  const [currentDevice, setCurrentDevice] = useState({});
  const [devicesData, setDevicesData] = useState([])
  const [currentSims, setCurrentSims] = useState([])
  const [userSubscriptions, setUserSubscriptions] = useState([])

  const navigate = useNavigate()

  async function showSimsForDevice(item) {
    setModalShown(!modalShown);

    if (item) {
      setCurrentDevice(item)
      await fetchDeviceSims(item.Id)
    }
  }

  const fetchDeviceSims = async (deviceId) => {
    try {
      const response = await fetch(global.SERVIP + `/device/get-sims/${deviceId}`); // Adjust the URL as needed
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setCurrentSims(data.simCards)
      return data;
    } catch (error) {
      console.error('Error fetching device SIM cards:', error);
    }
  };
  const fetchUserDevices = async () => {
    const token = Cookies.get('token'); // Get the token from cookies
    const url = `${global.SERVIP}/user/get_user_devices_info`; // Update the endpoint URL

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });

      if (!response.ok) {
        console.log(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setDevicesData(data.devices)
      // You can set state or process data here as needed
      return data;
    } catch (error) {
      console.error('Error fetching user devices:', error);
      throw error;
    }
  };
  const fetchSubscriptions = async () => {
    const token = Cookies.get('token'); // Get the token from cookies
    const url = `${global.SERVIP}/user/get_subscriptions`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setUserSubscriptions(data.subscriptions)
      return data;
    } catch (error) {
      console.error('Error fetching messages by month:', error);
      throw error;
    }
  };
  const fetchAllData = async () => {
    await fetchSubscriptions()
    await fetchUserDevices()
  }

  useEffect(() => {
    fetchAllData();
  }, []);
  return (
    <>
      <Head title="Your devices dashboard" />
      <Content>
        <h3>Your own service/devices overview</h3>
        <p style={{ margin: 10 }}>Check your active subscriptions, devices and activity</p>
        <Col>
          <Row className="my-3">
            <Col className="mb-4 mb-lg-0">
              <Card >
                <CardBody>
                  <DevicesTable showSimsForDevice={showSimsForDevice} devicesData={devicesData} />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <SubscriptionsCard subscriptions={userSubscriptions} selling={false} />

                </CardBody>
              </Card>
            </Col>

            <Col >
              <Card className="card h-100">
                <h4 style={{ margin: '5px' }} >Setup tutorial</h4>
                <CardBody>
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/9GVlUvht5mI?si=DCtclk1aitkBBZFB"
                    title="YouTube video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                  </iframe>
                </CardBody>
              </Card>
            </Col>
          </Row >
        </Col>
        <Modal isOpen={modalShown} toggle={() => setModalShown(false)}>
          <ModalHeader
            toggle={() => setModalShown(false)}
            close={
              <button className="close" onClick={() => setModalShown(false)}>
                <Icon name="cross" />
              </button>
            }
          >
            {currentDevice.name}
          </ModalHeader>
          <ModalBody>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">id</th>
                  <th scope="col">Provider</th>
                  <th scope="col">Country</th>
                  {/* Include other columns as needed */}
                </tr>
              </thead>
              <tbody>
                {currentSims.map((sim, index) => (
                  <tr
                    key={index}
                    onClick={() => navigate('/dashboard/sell/sim', { state: { id: sim.id } })}
                    className="clickable-row"
                  >
                    <th scope="row">{index + 1}</th>
                    <td>{sim.name}</td>
                    <td>{sim.sourceCountry}</td>
                    {/* Include other data as needed */}
                  </tr>
                ))}


              </tbody>
            </table>                  <p>
              This is the list of your SIM cards for {currentDevice.name} with selling enabled, select which one you want to edit(add/remove countries, change pricies or change content type).
            </p>
          </ModalBody>
          <ModalFooter className="bg-light">
            <span className="sub-text"><a href="#" style={{ color: 'blue' }}>Quick tutorial</a></span>
          </ModalFooter>
        </Modal>
      </Content>
    </>
  )
}

export default YourDevicesService