import React from "react";
import Head from "../../layout/head/Head";
import AuthFooter from "../auth/AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import Cookies from 'js-cookie';
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

const DashboardPasswordChange = () => {
  const navigate = useNavigate()
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const url = global.SERVIP + '/user/change-password'; // Adjust with your server URL if necessary
    const jwtToken = Cookies.get('token');
    if (oldPassword.length < 5 || newPassword.length < 5) {
      responseAlert("Password too short", false)
      return
    }
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwtToken}` // Set JWT token in the Authorization header
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword
        })
      });

      const data = await response.json();

      if (data.ok) {
        // Handle success
        responseAlert(data.message, true)
      } else {
        responseAlert(data.message, false)
        // Handle failure
      }
    } catch (error) {
      responseAlert("Server error", false)
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const url = global.SERVIP + '/user/forgot-password';
    const jwtToken = Cookies.get('token');
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwtToken}` // Set JWT token in the Authorization header
        }
      });

      const data = await response.json();

      if (data.ok) {
        await Swal.fire({
          icon: "success",
          title: "Check you email",
          text: "Link with instructions should be sent to your email address shortly. Don't forget to check spam folder.",
          focusConfirm: false,
        });
        navigate("/dashboard")
      } else {
        responseAlert(data.message, false)
      }
    } catch (error) {
      responseAlert("Server error", false)
    }
  };
  const responseAlert = async (message, responseOk) => {

    await Swal.fire({
      icon: responseOk ? "success" : "error",
      title: responseOk ? "Password changed" : "Error",
      text: responseOk ? "Password successfully" : message, // Use 'html' instead of 'text' to render HTML content
      focusConfirm: false,
    });
  };

  return (
    <>
      <Head title="Change Password" />
      <div style={{ height: '50px' }} />
      <Block className="nk-block-middle nk-auth-body  wide-xs" >
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">Change Password</BlockTitle>
              <BlockDes>
                <p>Enter your current password and the new password you wish to set.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          <form onSubmit={handleChangePassword}>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="old-password">Old Password</label>
              </div>
              <input
                type="password"
                className="form-control form-control-lg"
                id="old-password"
                placeholder="Enter your old password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="new-password">New Password</label>
              </div>
              <input
                type="password"
                className="form-control form-control-lg"
                id="new-password"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <Button color="primary" size="md" outline={true} className="btn-block" type="submit">
                Change Password
              </Button>
            </div>
          </form>
          <Link onClick={handleForgotPassword}>Forgot your password?</Link>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <strong>Back</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
    </>
  );
};

export default DashboardPasswordChange;
