import {BrowserRouter as  Routes, Route, Link} from 'react-router-dom'
import HeaderOne from "../../header/HeaderOne"
import * as React from 'react'
import FooterOne from '../../footer/FooterOne'
import Head from '../../components/Head'

function WebTextingApp() {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <> 
      <Head title="Bulk SMS tool overview" />
      <HeaderOne />
      <section className="about-us-area pt-145 pb-90 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
        <div className="container" style={{marginTop:'10%'}}>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-text-wrapper2 mb-30 pr-75">
                <h6 className="semi-title semi-title2">Web SMS Application</h6>
                <h2 className="sect-title text-heding2 mb-35">Communicate with your employees, users and prospects one on one.</h2>
                <p className="paragraph-title">
                  In TextLink Web App, we incorporate all of the messaging functionalities that a SMS app should have, adding contacts, sending and receiving messages, sharing contacts,...
                  It's responsive and quick and it will make your life a whole lot easier, whether you are a dispatcher for a trucking company, communicating with potential leads or doing real-time customer support, we are here for you.
                  <br/>
                  <br/>
                  Since TextLink uses your own device/number, this will be just like texting from your phone, just better.
                </p>
                <ul className="text-list text-list2 list-none border-bottom" style={{marginTop:20}}>
                  <br/>
                  <br/>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div 
                className="about-img-wrapper mb-30"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={openModal}
                style={{ 
                  cursor: 'pointer',
                  transform: isHovered ? 'scale(1.05)' : 'scale(1)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <img 
                  className="img-one img-fluid d-xxl-inline-block rounded-3" 
                  src="assets/img/about/chat.png" 
                  alt="About Img"
                  style={{
                    border: isHovered ? '2px solid #000' : 'none',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>    

      <section>
        <div className="col-12" style={{display: 'flex',justifyContent: 'center',alignItems:'center',flexDirection:'column'}}>
          <ul className="btn-list2 list-none d-sm-flex align-items-center justify-content-center">
            <li><Link to="/faq" className="theme_btn mr-20">FAQ</Link></li>
            <li><Link to="/contact" className="theme_btn border-btn">Get In Touch <i className="fal fa-long-arrow-right"></i></Link></li>
          </ul>
        </div>
      </section> 
      <br/>

      <FooterOne/>      

      {isModalOpen && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={closeModal}
        >
          <img 
            src="assets/img/about/chat.png" 
            alt="Enlarged Img" 
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
          />
        </div>
      )}
    </>
  );
}

export default WebTextingApp;
