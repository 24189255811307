export default function WhyChooseUs(){
    return(<>
        <section className="grey-bg2 chose-us-area service-page pt-120 pb-90 pt-md-60 pt-xs-60 pb-xs-60">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title text-center mb-60">
								<p className="lh-1 mb-10">Why Choose Us</p>
								<h2 className="sect-title">Delivering optimal solution</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="white-bg chose-box d-sm-flex align-items-center mb-30">
								<div className="chose-content pr-30">
									<h5 className="fs-20">Warranty Management IT</h5>
									<p className="fs-14">Our service offerings enhance customer experience throughout
										secure & highly functional.</p>
								</div>
								<h3 className="number-text">01</h3>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="white-bg chose-box d-sm-flex align-items-center mb-30">
								<div className="chose-content pr-30">
									<h5 className="fs-20">Product Engineering & Services</h5>
									<p className="fs-14">Our service offerings enhance customer experience throughout
										secure & highly functional.</p>
								</div>
								<h3 className="number-text">02</h3>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="white-bg chose-box d-sm-flex align-items-center mb-30">
								<div className="chose-content pr-30">
									<h5 className="fs-20">Quality Control System</h5>
									<p className="fs-14">Our service offerings enhance customer experience throughout
										secure & highly functional.</p>
								</div>
								<h3 className="number-text">03</h3>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="white-bg chose-box d-sm-flex align-items-center mb-30">
								<div className="chose-content pr-30">
									<h5 className="fs-20">Infrastructure Integration Technology</h5>
									<p className="fs-14">Our service offerings enhance customer experience throughout
										secure & highly functional.</p>
								</div>
								<h3 className="number-text">04</h3>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="white-bg chose-box d-sm-flex align-items-center mb-30">
								<div className="chose-content pr-30">
									<h5 className="fs-20">Highly Professional Staffs</h5>
									<p className="fs-14">Our service offerings enhance customer experience throughout
										secure & highly functional.</p>
								</div>
								<h3 className="number-text">05</h3>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="white-bg chose-box d-sm-flex align-items-center mb-30">
								<div className="chose-content pr-30">
									<h5 className="fs-20">Information Security Management</h5>
									<p className="fs-14">Our service offerings enhance customer experience throughout
										secure & highly functional.</p>
								</div>
								<h3 className="number-text">06</h3>
							</div>
						</div>
					</div>
				</div>
			</section>
            </>
    )
    
}