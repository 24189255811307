import Head from "../../layout/head/Head"
import Content from "../../layout/content/Content"
import { Row, Col, Button } from "reactstrap"
import { Accordian, PreviewAltCard } from "../../components/Component"
import {
    SpecialTable,
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    BackTo,
    OrderTable,
    LoginLogTable,
} from "../../components/Component";
import {
    Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, CardBody, CardSubtitle, CardTitle, CardLink, CardText
} from "reactstrap";
import { Icon } from "../../components/Component"
import BulkSmsFaq from "./components/BulkSmsFaq";
import { CampaignsTable } from "./components/CampaignsTable";
import { RecipientGroupsTable } from "./components/RecipientGroupsTable";
import UserGroup from "./components/UserGroup";
import { useEffect, useState } from "react";
import CraftMessageDnd from "./components/CraftMessageDnd";
import { PreviewCard } from "../../components/Component";
import SingleListDnd from "../../components/partials/dnd/SingleListDnd";
import CreateMessageComponent from "./components/CreateMessageComponent";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import Cookies from "js-cookie"
import { Select } from 'antd';
const { Option } = Select;

function StartCampaign() {
    const token = Cookies.get('token');
    const [name, setName] = useState("");
    const [groupId, setGroupId] = useState(-1);
    const [fields, setFields] = useState([])
    const [firstContactValues, setFirstContactValues] = useState({})
    const [contactCountries, setContactCountries] = useState([])
    const [messagePrice, setMessagePrice] = useState(0)
    const [templateText, setTemplateText] = useState("")
    const [price, setPrice] = useState(0);
    const [groupVersions, setGroupVersions] = useState({});
    const [last, setLast] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countries, setCountries] = useState([])
    const [deliverablity, setDeliverability] = useState(1);
    const [wait, setWait] = useState("1")

    const formatPercent = (number) => {
        return Math.floor(parseFloat(number) * 100);
    }

    // JSON.stringify({groupId, version: groupVersions[groupId], text})
    const getChangeIdentifier = (id, version, text) => {
        return JSON.stringify({ id, version, text, selectedCountry });
    }
    const changeIdentifier = getChangeIdentifier(groupId, groupVersions[groupId], templateText);

    const navigate = useNavigate()

    const fetchGroupInfo = async (id) => {
        try {
            const url = `${global.SERVIP}/outreach/groups/get-campaign-info`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ id })
            });
            if (!response.ok)
                return;
            const data = await response.json();
            if (!data.ok)
                return;

            setFields(data.fields);
            setFirstContactValues(data.first_contact_values);
            setContactCountries(data.contact_countries)
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        if (groupId > 0)
            fetchGroupInfo(groupId)
    }, [groupId])

    const startCampaign = async (name, group_id, message_text, pause) => {
        try {
            const url = `${global.SERVIP}/outreach/campaigns/create`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ name, group_id, message_text, pause })
            });
            if (!response.ok)
                return { ok: false, message: "Server error" };
            const data = await response.json();
            if (!data.ok)
                return data;

            setFields(data.fields);
            setFirstContactValues(data.first_contact_values);
            setContactCountries(data.contact_countries)
            return data;
        }
        catch (e) {
            return { ok: false, message: "Server error" };
        }
    }

    useEffect(() => {
        if (!token) {
            navigate("/signup")
        }
        const fetchCountries = async () => {
            try {
                const response = await fetch(`${global.SERVIP}/countries/get-all`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                data.sort((a, b) => {
                    if (a.name < b.name)
                        return -1;
                    if (a.name > b.name)
                        return 1;
                    return 0;
                }
                );
                setCountries(data);
            } catch (err) {
            } finally {
            }
        };

        fetchCountries();
    }, []);

    const startPopup = async () => {
        Swal.fire({
            title: "Start this campaign",
            text: (changeIdentifier == last) ? `Your campaign will cost ${price}$, do you want to start it` : "Are you sure you want to start the campaign without knowing its cost? If you want to see the campaign cost, close this dialog and click the \"Calculate\" button. ",
            icon: (changeIdentifier == last)?"question":"warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const result = await startCampaign(name, groupId, templateText, parseInt(wait) * 60000)
                if (result.ok) {
                    Swal.fire("Congratulations!", "Your campaign has started. Good luck with your leads.", "success");
                    navigate("/dashboard/bulksms-dashboard")
                }
                else {
                    Swal.fire("Error!", result.message, "error");
                }

            }
        });
    };
    const createNewCampaign = () => {
        if (!parseInt(wait) || parseInt(wait) < 1)
            return Swal.fire("Error!", "Wait amount should be a number greater than or equal to 1.", "error");
        if (!groupId || !templateText)
            return Swal.fire("Error!", "Select the group and enter text first.", "error");
        startPopup()
    }


    const calculatePrice = async (group_id, text) => {
        if (!groupId || !text)
            return Swal.fire("Error!", "Select the group and enter text first.", "error");
        try {
            const url = `${global.SERVIP}/outreach/campaigns/calculate-price`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ group_id, text, source_country: selectedCountry || undefined })
            });
            if (!response.ok)
                return;
            const data = await response.json();
            if (!data.ok)
                return Swal.fire("Error!", data.message, "error");
            setPrice(data.price);
            setDeliverability(data.deliverability_rate)
            setLast(changeIdentifier);

        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <Head title="Start SMS campaign" />
            <Content>
                <h4 style={{ marginBottom: 10 }}>Here you can create and start sms campaigns without code.</h4>
                <p style={{ marginBottom: 20 }}>Create/choose user groups with their respected attributes, piece together a message you want to send based on the user and start the campaign.</p>

                <Row>
                    <Col lg="8">
                        <Card className="card h-100">
                            <CardBody className="card-inner" >
                                <CardTitle tag="h4">Create a new campaign</CardTitle>
                                <p>Choose a name for your campaign. This can be anything you like. (Sales prospects, Discount reminder, ...)</p>
                                <br />
                                {/* Campaign Name */}
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <input className="form-control form-control-md" placeholder="Campaign Name" style={{ maxWidth: '400px', marginBottom: '20px' }} value={name} onChange={(e) => setName(e.target.value)} />
                                </div>
                                {/* User Group Selection */}
                                <h6>Choose a sender country (optional)</h6>

                                <p>Choose country where messages will originate from. Note that we may not have senders for all source countries.
                                </p> <br></br>
                                <div style={{ textAlign: "center" }}>
                                    <Select value={selectedCountry} defaultValue={null} placeholder="Select sender country" style={{ flexGrow: 1, minWidth: '200px', margin: 'auto' }} onChange={setSelectedCountry}>
                                        <Option value={null}>Any country</Option>
                                        {countries.filter(e => e.calling_code).map(country => <Option value={country.iso2}>{country.name} ({country.calling_code.startsWith('+') ? country.calling_code : ('+' + country.calling_code)})</Option>)}
                                    </Select>
                                    {(changeIdentifier == last) ? (<span style={{ marginLeft: 10 }}>{`Deliverablility rate: ${(formatPercent(deliverablity))}%`}</span>) :
                                        <Button color="primary" style={{ display: "inline", marginLeft: 10 }} onClick={() => { calculatePrice(groupId, templateText) }}>Calculate deliverablity rate</Button>}
                                </div>
                                <br></br>
                                {/*<p>Fine-tune the campaign speed below. Time between two messages needs to be 1 minute or higher.</p>
                                <br></br>
                                <div style={{ textAlign: 'center' }}>
                                    Send a message every <input style={{ maxWidth: '100px' }} value={wait} onChange={(e) => {
                                        //setDuration("");
                                        setWait(e.target.value)
                                    }} /> minutes
                                    {/*<p>or</p>
                                    Finish the campaign after <input style={{ maxWidth: '100px' }} value={duration} onChange={(e) => {
                                        setDuration(e.target.value);
                                        setWait("")
                                    }} />  minutes}
                                </div>*/}
                                <br></br>
                                <h6>Choose or create user group</h6>
                                <br />
                                <p>Choose your custumer group, or create a new one.<br /> Each group has 1 mandatory column (phoneNumber). Groups are imported from Excel file (each coulmn is a new value, you can add anything you like, address, name, surname, ...), or created manualy. Check out our video on creating groups <a style={{ color: 'blue' }} href="https://yourube.com">here</a></p>
                                <br />
                                <div style={{ marginBottom: '20px', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                    <RecipientGroupsTable saveGroupUpdateCallback={(id) => {
                                        if (groupVersions[id]) {
                                            groupVersions[id]++;
                                        }
                                        else {
                                            groupVersions[id] = 1;
                                        }
                                        setGroupVersions({ ...groupVersions });
                                    }} selectable={true} setSelected={setGroupId}></RecipientGroupsTable>
                                </div>
                                {/* Message Creation */}
                                <PreviewCard>
                                    <CreateMessageComponent setTemplateText={setTemplateText} fields={fields} firstContactValues={firstContactValues} contactCountries={contactCountries} setMessagePrice={setMessagePrice} />
                                </PreviewCard>
                               
                                <br />
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button color="primary" style={{ width: '70%' }} onClick={() => { createNewCampaign() }}>Start campaign</Button>

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4">
    {/* Troubleshooting Section */}
    <Card className="card h-100">
        <CardBody className="card-inner">
            <CardTitle tag="h6">Having trouble?</CardTitle>
            <p className="mb-2 ff-base">
                Check out this video for help. It will show you how to use this tool in just a couple of minutes.
            </p>
            {/* Embed YouTube Video */}
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                <iframe
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    src="https://www.youtube.com/embed/-gajhLRMYIE" // Replace VIDEO_ID with your video's ID
                    title="YouTube video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </CardBody>
    </Card>
</Col>

                </Row>
            </Content>
        </>
    );
}

export default StartCampaign