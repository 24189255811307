import React, { useState } from "react";
import { AudienceLineChart } from "../../components/partials/charts/analytics/AnalyticsCharts";
import { Icon } from "../../components/Component";
import {  analyticOvData,
    analyticAuData,
    worldMap,
    analyticOvDataSet2,
    analyticOvDataSet3,
    TrafficChannelDoughnutData,
    TrafficChannelDoughnutData2,
    TrafficChannelDoughnutData3,
    TrafficChannelDoughnutData4,
    deviceStatusData,
    deviceStatusDataSet2,
    deviceStatusDataSet3,
    deviceStatusDataSet4, } from "../../components/partials/charts/analytics/AnalyticsData";

import { Line } from "react-chartjs-2";    
import { useEffect } from "react";

 const SpendingLineChart = ({ chartData }) => {
    if (!chartData || !chartData.datasets || !Array.isArray(chartData.datasets)) {
        return <p>Chart data is not available.</p>;
      }
  
    return (
      <Line
        className="analytics-line-large"
        data={chartData}
        options={{
          plugins: {
           
            tooltip: {
                enabled: true,
                displayColors: false,
                backgroundColor: "#eff6ff",
                titleFont: {
                  size: '13px',
                },
                titleColor: "#6783b8",
                titleMarginBottom: 6,
                bodyColor: "#9eaecf",
                bodyFont: {
                  size: '12px',
                },
                bodySpacing: 4,
                padding: 10,
                footerMarginTop: 0,
            },
          },
          maintainAspectRatio: false,
          scales: {
            y:{
                display: true,
                ticks: {
                  beginAtZero: true,
                  color:"#9eaecf", 
                  font: {
                    size: '11px',
                  },
                  padding: 8,
                  stepSize: 2400,
                },
                grid: {
                  color: "rgba(82, 100, 132, 0.2)",
                  tickMarkLength: 0,
                  zeroLineColor: "rgba(82, 100, 132,0.2)",
                },
            },
            x:{
                display: false,
                ticks: {
                  color:"#9eaecf", 
                  font: {
                    size: '12px',
                  },
                  source: "auto",
                  padding: 0,
                },
                grid: {
                  color: "transparent",
                  tickMarkLength: 0,
                  zeroLineColor: "transparent",
                  offsetGridLines: true,
                },
            },
          },
        }}
      ></Line>
    );
  };

  const SendingSpendingChart = ({ messagesByMonth }) => {
    const [auOverview, setAuOverview] = useState("month-1");
    const [chartData, setChartData] = useState({});
    const [totalMessages, setTotalMessages] = useState(0);
    const [totalSpending, setTotalSpending] = useState(0);
    const [avgCostPerSms, setAvgCostPerSms] = useState(0);
  
    useEffect(() => {
    if (messagesByMonth && Array.isArray(messagesByMonth) && messagesByMonth.length > 0) {
        const labels = messagesByMonth.map(item => item.month);
        const messageCounts = messagesByMonth.map(item => parseFloat(item.messageCount));
        const totalPrices = messagesByMonth.map(item => parseFloat(item.totalPrice));
  
        // Calculate totals and average
        const totalMessagesCalc = messageCounts.reduce((acc, count) => acc + count, 0);
        const totalSpendingCalc = totalPrices.reduce((acc, price) => acc + price, 0);
        const avgCostPerSmsCalc = totalMessagesCalc ? (totalSpendingCalc / totalMessagesCalc).toFixed(2) : 0;
  
        setTotalMessages(totalMessagesCalc);
        setTotalSpending(totalSpendingCalc);
        setAvgCostPerSms(avgCostPerSmsCalc);
  
        const data = {
            labels: labels,
            datasets: [
              {
                label: 'Number of Messages',
                data: messageCounts,
                backgroundColor: 'rgba(75, 192, 192, 0.5)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
                tension: 0.4
              },
           
            ]
          };
          
  
        setChartData(data);
  
        // Update the state for total messages, total spending, and average cost per SMS
        // ... (You may need to add state variables and setters for these values)
      }
    }, [messagesByMonth]);
    
  return (
    <React.Fragment>
      <div className="card-title-group pb-3 g-2">
        <div className="card-title card-title-sm">
          <h6 className="title">Usage overview</h6>
          <p>How many messages you have sent, and some other information.</p>
        </div>
       
      </div>
      {messagesByMonth && messagesByMonth.length > 0 ? <div className="analytic-ov">
      <div className="analytic-data-group analytic-ov-group g-3">
        <div className="analytic-data analytic-ov-data">
          <div className="title">Messages</div>
          <div className="amount">{totalMessages}</div>
        </div>
       
      </div>
       
      <div className="analytic-ov-ck">
      
          <SpendingLineChart chartData={chartData} />
       
         
      </div>
      <div className="chart-label-group ms-5">
  {messagesByMonth && messagesByMonth.length>0 && messagesByMonth.map((item, index) => (
    <div key={index} className="chart-label">{item.month}</div>
  ))}
</div>

      </div> : <p>No messages sent</p> }
    </React.Fragment>
  );
};
export default SendingSpendingChart;