import React, { useEffect, useState } from "react";
import {
  Row, BlockBetween,
  BlockDes,
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Col,
} from "../../components/Component";
import { Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import SubscriptionComponent from './components/SubsctiptionComponent'
import Cookies from "js-cookie";

function SubscriptionsPricing({ myTargetRef }) {
  const navigate = useNavigate();
  const token = Cookies.get('token');
  const [subscriptions, setSubscriptions] = useState([]);
  const [lookupKey, setLookupKey] = useState('free');
  const [yearlyPlan, setYearlyPlan] = useState(false); // Global state for yearly/monthly

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/subscriptions/get-all`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        data.subscriptions.sort((a, b) => (a.price - b.price));
        setSubscriptions(data.subscriptions);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        // Handle errors here
      }
    };

    fetchSubscriptions();

    const fetchSubscriptionData = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/payment/get-subscription-info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const { ok, lookup_key } = await response.json();
        if (!ok) {
          return;
        }

        setLookupKey(lookup_key);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    fetchSubscriptionData();
  }, []);

  const getUsersSubscription = (subscriptions, lookup_key) => {
    for (let subscription of subscriptions) {
      if (subscription.lookup_key === lookup_key) {
        return subscription;
      }
    }
    return { lookup_key: 'free', price: 0 };
  };

  return (
    <>
      <BlockHead size="sm">
        <BlockBetween className="g-3">
          <BlockContent>
            <BlockTitle>Purchase Subscriptions</BlockTitle>
            <BlockDes className="text-soft">
              <p ref={myTargetRef}>This payment model is for using your own numbers and SIM cards to send messages, check <Link style={{ color: 'blue' }} to="/dashboard/your-devices/get-started">this link</Link> for more information.</p>
              <Button onClick={() => navigate("/dashboard/your-devices/get-started")} style={{ color: 'black'}} color="light">How do Use your devices subscriptions work?</Button>
            </BlockDes>
          </BlockContent>
        </BlockBetween>
      </BlockHead>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
        <span style={{ marginRight: '10px' }}>Monthly</span>
        <input
          type="checkbox"
          className="theme-checkbox"
          checked={yearlyPlan}
          onChange={() => setYearlyPlan(!yearlyPlan)}
        />
        <span style={{ marginLeft: '10px' }}>Yearly</span>
      </div>

      <Block>
        <Row className="g-gs">
          {subscriptions.map((item) => (
            <Col md={4} lg={3} xl={3} key={item.id} className="mb-3">
              <SubscriptionComponent
                usersSubscription={getUsersSubscription(subscriptions, lookupKey)}
                item={item}
                yearlyPlan={yearlyPlan} // Pass the global state
              />
            </Col>
          ))}
          <div style={{ width: '100%', height: '15px' }}></div>
        </Row>
        <p >
  If you need larger limits or custom integration, contact us at  
  <a href="mailto:sales@textlinksms.com" style={{color:'blue'}}> sales@textlinksms.com</a>
</p>
      </Block>
    </>
  );
}

export default SubscriptionsPricing;
