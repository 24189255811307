import React from 'react';

const Tos = () => {
  const styles = {
    container: {
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      padding: '20px',
      backgroundColor: '#fff',
      color: '#333',
      lineHeight: '1.6',
    },
    header: {
      fontSize: '22px',
      fontWeight: 'bold',
      color: '#397EB6',
      marginBottom: '20px',
    },
    subHeader: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#397EB6',
      marginBottom: '10px',
    },
    text: {
      fontSize: '16px',
      marginBottom: '10px',
    },
    link: {
      color: '#397EB6',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    section: {
      marginBottom: '20px',
    },
  };

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>TextLink Terms of Service</h1>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Service Description:</h2>
        <p style={styles.text}>
          TextLink LTD offers an advanced SMS API Service enabling users to send SMS messages through our API seamlessly.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Payment Terms:</h2>
        <p style={styles.text}>
          Enjoy the first two messages on us, completely free of charge. Subsequently, our service operates on a pay-as-you-go basis, requiring users to purchase bulk SMS packages for continued usage.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Refund Policy:</h2>
        <p style={styles.text}>
          We stand by our service's reliability and user satisfaction. Should you find yourself unsatisfied with our service, we offer refunds for unused messages within your purchased packages, provided no violation of our terms has occurred. Refund requests must be submitted within 7 days from the date of purchase.
        </p>
        <p style={styles.text}>
          Should your needs change, we also offer the flexibility to swap your current subscription, package, or credits for another service of equivalent value within 7 days of purchase. For assistance, please reach out to <span style={styles.link} onClick={() => openLink('mailto:support@textlinksms.com')}>support@textlink.com</span>.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Use of Service:</h2>
        <p style={styles.text}>
          The TextLink LTD SMS API Service may only be used for lawful purposes and in accordance with the terms of this agreement. Prohibited uses include, but are not limited to, sending spam or other unsolicited messages, sending illegal or harmful content, or using the service to harass or abuse others. Users may only send promotional messages to recipients who have given their explicit consent.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Limitation of Liability:</h2>
        <p style={styles.text}>
          We shall not be liable for any damages or losses incurred as a result of the use of our TextLink LTD SMS API Service.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Termination of Service:</h2>
        <p style={styles.text}>
          We reserve the right to terminate the TextLink LTD SMS API Service or any user's access to the service at any time and without notice, in the event of a violation of these terms or for any other reason.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Changes to Terms of Service:</h2>
        <p style={styles.text}>
          We reserve the right to modify these terms at any time, and such changes will be effective immediately upon posting. Your continued use of the TextLink LTD SMS API Service constitutes your acceptance of the revised terms.
        </p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.subHeader}>Governing Law:</h2>
        <p style={styles.text}>
          This agreement shall be governed by and construed in accordance with the laws of the country in which the service is provided.
        </p>
      </div>

      <div style={styles.section}>
        <p style={styles.text}>
          By using our TextLink LTD SMS API Service, you agree to be bound by these terms and conditions.
        </p>
      </div>
    </div>
  );
};

export default Tos;
