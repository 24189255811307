import React from 'react';
import './Other.css';
import Head from '../../components/Head';
import HeaderOne from '../../header/HeaderOne';

const ProductComparisonTable = () => {
  const products = [
    { name: 'TextLink', price: '$0.001/sms', link: 'Link', features: [true, true,false, true, true] },
    { name: 'Twilio', price: '$0.01/sms', link: 'Link', features: [false, false,  true, true, true] },
    { name: 'MessageBird', price: '$0.01/sms', link: 'Link', features: [false, false,  true, false, true] },
    { name: 'EZTexting', price: '$0.03/sms', link: 'Link', features: [false, false, true, false, true] },
  ];

  const features = [
    'No Identity Verification Required', 
    'All Content Allowed', 
    'Possible Restriction ',
    'Support Available', 
    'International Messaging'
  ];

  return (
    <>
      <Head title="Services overview" />
      <HeaderOne />
     
      <section className="core-feature-area pt-120 pt-md-60 pt-xs-60 pb-100 pb-md-30 pb-xs-30 home-4" style={{ backgroundColor: 'white' }}></section>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems:'center', color:'black', marginTop:"5%"}}>
        <h2 style={{color:'black'}}>Pricing and Feature Comparisons</h2>
        <p style={{color:'black'}}>Overview of how TextLink compares to some of the popular messaging services.</p>
      </div>
      <div className="comparison-container">
        <table className="comparison-table">
          <thead>
            <tr>
              <th>Features</th>
              {products.map((product, index) => (
                <th key={index}>
                  <div className="header-custom122">{product.name}</div>
                  <div className="price-container122">from {product.price}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature, featureIndex) => (
              <tr key={featureIndex}>
                <th scope="row">{feature}</th>
                {products.map((product, productIndex) => (
                  <td key={productIndex}>
                    {typeof product.features[featureIndex] === 'boolean'
                      ? product.features[featureIndex]
                        ? <span>&#10004;</span>
                        : <span>&#10008;</span>
                      : product.features[featureIndex]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ProductComparisonTable;
