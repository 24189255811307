import React, { useState } from 'react';
import { trackContact } from '../AnalyticsEvents/MetaPixelEvents';

export default function ContactForm() {
    const [formStatus, setFormStatus] = useState('Send');

    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus('Submitting...');

        // Extracting form data from the form elements
        const { name, email, job, message } = e.target.elements;
        let formData = {
            fullName: name.value,
            email: email.value,
            reason: job.value,
            message: message.value,
        };

        // Define the endpoint URL
        const endpoint = global.SERVIP+"/guest/send-email"; // Update this URL

        try {
            // Making the API call
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();

            // Handle the response accordingly
            if (response.ok) {
                trackContact()
                setFormStatus('Sent Successfully');
                alert("Thank you for contacting us!");
                // Optionally reset form fields here
            } else {
                throw new Error(result.message || 'Failed to send message');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setFormStatus('Failed to send');
            alert(`Error: ${error.message}`);
        }
    };
    return(
        <form className="row contact-form mb-30" onSubmit={onSubmit}>
            <div className="col-lg-6 col-md-6">
                <div className="input-box mb-20">
                    <input type="text" name="name" placeholder="Full name " />
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="input-box mail-input mb-20">
                    <input type="text"  name="email" placeholder="Email address **" />
                </div>
            </div>
            <div className="col-lg-12 mb-20">
                <div className="input-box sub-input">
                    <select name="job" className="job-select">
                        <option value="Pricing">Pricing</option>
                        <option value="Setup">Setup</option>
                        <option value="Technical issue">Technical issue</option>

                        <option value="Other">Other</option>

                    </select>
                </div>
            </div>
            <div className="col-12">
                <div className="input-box text-input mb-15">
                    <textarea name="message" cols="30" rows="10"
                        placeholder="Enter massge **"></textarea>
                </div>
            </div>
            <div className="col-12">
                <button className="form-btn form-btn3">Get Free Consultation</button>
            </div>
        </form>
    )
}
