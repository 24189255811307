import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';

const ChangeSubuserPassword = ({ token, id, hasPassword, onChangePassword }) => {
    const [modal, setModal] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState(null);
    const [isError, setIsError] = useState(false);

    const toggle = () => {
        if (!modal) {
            setPassword('');
            setConfirmPassword('');
        }
        setModal(!modal);
        setMessage(null);
        setIsError(false);
    };

    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            setMessage('Passwords do not match.');
            setIsError(true);
            return;
        }

        const url = `${global.SERVIP}/user/change-subuser-password`;
        const body = { id, password };
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            });
            const result = await response.json();

            if (result.ok) {
                setMessage('Password has been changed successfully.');
                setIsError(false);
                setTimeout(toggle, 1500);
                onChangePassword(result.subuser);
            } else {
                setMessage(result.message || 'Server error');
                setIsError(true);
            }
        } catch (error) {
            setMessage('Network error');
            setIsError(true);
        }
    };

    return (
        <>
            <a
                href="#toggle"
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    toggle();
                }}
                className="link link-primary"
            >
                {hasPassword ? "Change password" : "Set password"}
            </a>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader>{hasPassword ? "Change Password" : "Set Password"}</ModalHeader>
                <ModalBody>
                    {message && (
                        <Alert color={isError ? 'danger' : 'success'}>
                            {message}
                        </Alert>
                    )}
                    <Form>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                                placeholder="Enter password"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="confirmPassword">Confirm Password</Label>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                placeholder="Confirm password"
                            />
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" checked={showPassword} onChange={toggleShowPassword} />
                                Show Password
                            </Label>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default ChangeSubuserPassword;
