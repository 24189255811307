import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../../components/Component";
import { findUpper } from "../../../utils/Utils";
import {  DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";

const UserProfileAside = ({updateSm,sm}) => {
  const [profileName, setProfileName] = useState("Abu Bin Ishtiak");
  
  useEffect(() => {
    sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [sm])
  
  return (
    <div className="card-inner-group">

    <div className="card-inner p-0">
        <ul className="link-list-menu">
        <li onClick={() => updateSm(false)}>
            <Link
            to={`${process.env.PUBLIC_URL}/dashboard/sell/get-started`}
            className={
                window.location.pathname === `${process.env.PUBLIC_URL}/dashboard/sell/get-started` ? "active" : ""
            }
            >
            <Icon name="user-fill-c"></Icon>
            <span>Explanation</span>
            </Link>
        </li>
        <li onClick={() => updateSm(false)}>
            <Link
            to={`${process.env.PUBLIC_URL}/dashboard/sell/step-by-step`}
            className={
                window.location.pathname === `${process.env.PUBLIC_URL}/dashboard/sell/step-by-step`
                ? "active"
                : ""
            }
            >
            <Icon name="bell-fill"></Icon>
            <span>Step by step guide</span>
            </Link>
        </li>
        <li onClick={() => updateSm(false)}>
            <Link
            to={`${process.env.PUBLIC_URL}/dashboard/sell/sell-video`}
            className={
                window.location.pathname === `${process.env.PUBLIC_URL}/dashboard/sell/sell-video` ? "active" : ""
            }
            >
            <Icon name="activity-round-fill"></Icon>
            <span>Video tutorial</span>
            </Link>
        </li>
       
        </ul>
    </div>
    </div>
  );
};

export default UserProfileAside;
