import React, { useState, useCallback, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { Button } from "reactstrap";
import Swal from "sweetalert2";

const ContactsGrid = ({ columnDefs, setColumnDefs, rowData, setRowData, CustomHeader }) => {
    const gridApiRef = useRef(null);


    const [name, setName] = useState('');

    const onGridReady = params => {
        gridApiRef.current = params.api;
        params.api.sizeColumnsToFit();
    };


    const onCellValueChanged = useCallback((params) => {
        // Validate the edited cell

        // Update rowData state
        let updatedRowData = [...rowData];
        if ((params.colDef.field == 'Phone Number') && params.value && !params.value.toString().startsWith("+") && isValidPhoneNumber('+' + params.value)) {
            updatedRowData[params.node.rowIndex][params.colDef.field] = '+' + params.value;
        }
        else {
            updatedRowData[params.node.rowIndex][params.colDef.field] = params.value || '';
        }

        // If last row is being edited, add a new empty row
        if (params.node.rowIndex === updatedRowData.length - 1) {
            updatedRowData.push({});
        }

        // Remove extra empty rows, leaving only one at the end
        updatedRowData = updatedRowData.filter((row, index) => {
            return index === updatedRowData.length - 1 || Object.keys(row).length !== 0;
        });

        setRowData(updatedRowData);
    }, [rowData]);

    useEffect(() => {
        gridApiRef.current && gridApiRef.current.sizeColumnsToFit();
    }, [columnDefs])


    return (
        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
                <input className="form-control form-control-md" placeholder="New column name" style={{ display: "flex", flexGrow: 1 }} value={name} onChange={(e) => setName(e.target.value)} />
                <Button style={{ whiteSpace: "nowrap", marginLeft: 5 }} onClick={() => {
                    if (!name)
                        return Swal.fire({
                            title: "Error",
                            text: 'You have to input a field name first',
                            icon: "error"
                        })
                    setColumnDefs(old => {
                        const newColumn = {
                            field: name,
                            editable: true,
                            cellEditor: 'agTextCellEditor',
                            headerComponent: CustomHeader,
                            headerName: name,
                            deletable: true
                        };

                        if (old.length > 0) {
                            const lastColumn = old.pop();
                            return [...old, newColumn, lastColumn];
                        } else {
                            return [newColumn];
                        }
                    });
                    setName('');
                }} color="primary">
                    Add column
                </Button>
            </div>

            <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                onCellValueChanged={onCellValueChanged}
                singleClickEdit={true}
                onGridReady={onGridReady}
                stopEditingWhenCellsLoseFocus={true}
                suppressMovableColumns={true}
            />
        </div >
    );
};

export default ContactsGrid;
