import React, { useState, useEffect } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import {Button} from "reactstrap";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
const User = ({email}) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const toggle = () => setOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <div className="user-toggle">
          <UserAvatar icon="user-alt" className="sm" />
          <div className="user-info d-none d-md-block">
            <div className="user-status">{Cookies.get("subuser") == "true" ? "User ":"Admin " }Account</div>
            <div className="user-name dropdown-indicator">{email}</div>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <div className="user-card sm">
            <div className="user-avatar">
              <span>{email && email[0].toUpperCase()}</span>
            </div>
            <div className="user-info">
              <span className="sub-text">{email}</span>
            </div>
          </div>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <LinkItem link="/dashboard/user" icon="user-alt" onClick={toggle}>
              View Profile
            </LinkItem>
          
           
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList  onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            Cookies.remove('token');
            navigate("/");
          }}>
            <Button style={{fontSize:12, width:'100%'}}  color="danger"   onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            Cookies.remove('token');
            window.location.replace("/");
            
          }}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </Button>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
