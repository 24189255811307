import Head from "../../layout/head/Head"
import Content from "../../layout/content/Content"

import {
    Row,
    Col,
    Card,
    CardHeader,
    CardFooter,
    CardImg,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardLink,
    DropdownMenu, DropdownItem, DropdownToggle,UncontrolledDropdown
  } from "reactstrap";
  import { Block, BlockHead, BlockHeadContent, BlockTitle, BlockDes, BackTo } from "../../components/block/Block";
  import { PreviewCard, CodeBlock } from "../../components/preview/Preview";
  import ActionCenter from "../../components/partials/crypto/action-center/ActionCenter";
    import { Icon } from "../../components/Component";
import Button from "../../components/button/Button";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function Intro1(){
  const [usePreference,setUsePreference] = useState(0)
  let navigate = useNavigate();

    return(
        <>
        <Head title="Card Widgets"></Head>
      <Content page="component">
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
           <br/>
           <br/>
            <BlockTitle tag="h2" className="fw-normal">
              Welcome To TextLink!
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>


          <PreviewCard>
            <Row>
              <Col lg="12">
                <Card className="card-bordered card-full">
                <div className="card-inner-group">
        <div className="card-inner card-inner-md">
          <div className="card-title-group">
            <CardTitle>
              <h6 className="title">What are you plaing on using our service for?</h6>
            </CardTitle>
           <br/>
           <br/>
          </div>
        </div>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="cc-alt-fill"></Icon>
              <div className="title">Sell SMS</div>
              <p>
                Sell messages through your device and <strong>earn as much as 1000$ a month</strong> , set it up in minutes.
              </p>
            </div>
            <Button className="btn-icon btn-trigger me-n2" onClick={()=>navigate("/dashboard")}>
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
            <Icon name="wallet-fill"></Icon>
              <div className="title">Buy SMS</div>
              <p>
               Buy sms through us, or send with your own devices and sim cards <strong>cheapest SMS </strong>on the market.<br/> (For custumers who want to send messages){" "}
              </p>
            </div>
            <Button className="btn-icon btn-trigger me-n2" onClick={()=>navigate("/dashboard")}>
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
            <Icon name="help-fill"></Icon>
              <div className="title">Both</div>
              <p>
                Do you want to do both? <strong>buy and sell</strong> at the same time.
              </p>
            </div>
            <Button className="btn-icon btn-trigger me-n2" onClick={()=>navigate("/dashboard")}>
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
      </div>                
      </Card>
              </Col>
            </Row>
          </PreviewCard>
         </Content>
         </>
    )
}

export default Intro1