import { Link } from "react-router-dom";
import Breadcrumb from "../components/BreadCurmb";
import FooterOne from "../footer/FooterOne";
import HeaderOne from "../header/HeaderOne";

export default function ServiceDetails(){
	return(
		<>
		<HeaderOne/>
		<Breadcrumb pagetitle={"Service Details"} subtitle={"Responsive & functional IT design"} title={"Services Single"}/>				
		<section className="about-services-area pt-120 pb-130 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="about-services-img mb-30">
							<img className="img-one img-fluid" src="assets/img/services/service-1.jpg" alt="Service Img" />
							<img className="img-two" src="assets/img/services/service-2.jpg" alt="Service Img" />
						</div>
					</div>
					<div className="col-lg-6">
						<div className="about-services-text ps-xxl-5 mb-30 pt-30">
							<h3 className="service-title-1 mb-30">An effective business strategy for companies</h3>
							<p className="mb-35">Can we help our clients succeed by creating brand identity, digital
								experiences, and print materials. Financial consulting slogans and
								Financial consu lting be used to communic.</p>
							<div className="plan-box d-sm-flex align-items-center">
								<div className="icon d-flex align-items-center">
									<img src="assets/img/icon/s-5a.png" alt="Icon" />
									<h5>Looking For A First Class For Business
										Plan Consultant?</h5>
								</div>
								<Link className="d-block" to="/contact"><i className="fal fa-long-arrow-right"></i></Link>
							</div>
							<p className="mb-35">We help our clients succeed by creating brand identities, digital IT
								experiences, and print materials.</p>
							<Link to="/services" className="theme_btn theme_btn3">Learn More <i
									className="fal fa-long-arrow-right"></i></Link>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section className="grey-bg2 services-tools pt-120 pb-190 pt-md-60 pb-md-60 pt-xs-60 pb-xs-30">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-md-6">
						<div className="white-bg tool-box text-center mb-30">
							<img src="assets/img/icon/s-6a.png" alt="Icon" />
							<h5 className="fs-20">Marketing Tool #01</h5>
							<p className="fs-14">We work to understand your
								issues and are driven</p>
							<div className="number">02</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="white-bg tool-box text-center mb-30">
							<img src="assets/img/icon/s-6b.png" alt="Icon" />
							<h5 className="fs-20">Marketing Tool #02</h5>
							<p className="fs-14">We work to understand your
								issues and are driven</p>
							<div className="number">02</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="white-bg tool-box text-center mb-30">
							<img src="assets/img/icon/s-6c.png" alt="Icon" />
							<h5 className="fs-20">Marketing Tool #03</h5>
							<p className="fs-14">We work to understand your
								issues and are driven</p>
							<div className="number">02</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-6">
						<div className="white-bg tool-box text-center mb-30">
							<img src="assets/img/icon/s-6d.png" alt="Icon" />
							<h5 className="fs-20">Marketing Tool #04</h5>
							<p className="fs-14">We work to understand your
								issues and are driven</p>
							<div className="number">02</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		
		<section className="testimonial-area pb-120 pb-md-60 pb-xs-60">
			<div className="container">
				<div className="white-bg testimonial-bg3 pt-90 pt-md-60 pt-xs-60">
					<div className="row pb-80">
						<div className="col-lg-6">
							<div className="testimonial-text-wrapper3 count-line text-center pr-45 pl-65">
								<img className="icon-big" src="assets/img/icon/quote-big.png" alt="Icon" />
								<p className="sub-title">“ The completely synergize resource taxing
									relationships via premier niche markets.
									Professionally cultivate one-to-one customer
									service with robust ideas. ”</p>
								<div className="author-box d-flex align-items-center justify-content-center">
									<img className="authors" src="assets/img/services/service-3.jpg" alt="Image" />
									<div className="authors-info text-start">
										<h5>Miranda H. Halim</h5>
										<p className="fs-14">Head Of Idea</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="testimonial-text-wrapper3 text-center pr-65 pl-45">
								<img className="icon-big" src="assets/img/icon/quote-big.png" alt="Icon" />
								<p className="sub-title">“ The completely synergize resource taxing
									relationships via premier niche markets.
									Professionally cultivate one-to-one customer
									service with robust ideas. ”</p>
								<div className="author-box d-flex align-items-center justify-content-center">
									<img className="authors" src="assets/img/services/service-4.jpg" alt="Image" />
									<div className="authors-info text-start">
										<h5>Miranda H. Halim</h5>
										<p className="fs-14">Head Of Idea</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row mb-55">
						<div className="col-12">
							<p className="mb-20">A recognizable and loved brand is one of the most valuable assets a company
								owns.
								In fact, 59% of
								customers prefer to buy products from brands they know. How do you
								become a sought-after brand with endless sales and raving fans? By identifying your
								promise
								to a
								customer or client. The challenge is communicating a clear and cohesive
								message. Also known as an IT managed services provider, an IT support company is
								comprised
								of
								professional IT specialists who make up the company's core IT team. The
								team provides your. business with expert guidance and management for a variety of
								information
								technology needs.</p>
							<p><b className="text-heding text-decoration-underline">Waretech</b> team is a diverse network
								of
								consultants and
								industry professionals with a global
								mindset and a collaborative culture. We work to understand your issues and are
								driven to ask better questions in the pursuit.</p>
						</div>
					</div>
					<div className="row filter-bg align-items-center px-0 pt-20">
						<div className="col-lg-5 col-md-5 mb-20">
							<div className="prev-box d-flex align-items-center justify-content-start">
								<div className="author-img mr-20">
									<img src="assets/img/services/service-5.jpg" alt="Author Img" />
								</div>
								<div className="prev-content">
									<h5>Business Consultancy</h5>
									<p className="fs-14">Prev Service</p>
								</div>
							</div>
						</div>
						<div className="ham-icons col-lg-2 col-md-2 mb-20 text-start text-md-center">
							<img src="assets/img/icon/eclips-big.png" alt="Icon" />
						</div>
						<div className="col-lg-5 col-md-5 mb-20">
							<div className="prev-box next-box d-flex align-items-center justify-content-md-end">
								<div className="prev-content order-last order-md-first text-start text-md-end">
									<h5>Marketing Service</h5>
									<p className="fs-14">Next Service</p>
								</div>
								<div className="author-img ml-20">
									<img src="assets/img/services/service-6.jpg" alt="Author Img" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<FooterOne/>		
		</>
	)
}