import { Link } from 'react-router-dom';
import GetInTouch from './GetInTouch';
import QuickLinks from './QuickLinks';
import SearchPopUp from './SearchPopUp';
import ServicesMenu from './ServicesMenu';
import Subscribe from './Subscribe';
export default function FooterOne() {
   
    return (
        <>
<footer 
  className="black-soft-bg footer-area pt-95" 
  style={{ backgroundColor: '#f2f7ff', borderTop: '2px solid #000000' }}
>            <div className="container" >
                <div className="row mb-15" >
                   
                    <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay=".3s">
                        <div className="footer__widget mb-30">
                            <QuickLinks/>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay=".5s">
                        <div className="footer__widget form-widget mb-30">
                            <Subscribe/>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp2 animated" data-wow-delay=".7s">
                        <div className="footer__widget mb-30 pl-55 pl-lg-0 pl-md-0 pl-xs-0" >
                            <ServicesMenu/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-35" style={{backgroundColor:''}}>
                <div className="row align-items-center gx-lg-0 pt-15">
                    <div className="col-xl-3 col-lg-4 col-md-6 text-center text-md-start">
                        <div className="logo-area mb-15 pl-30 pl-md-0 pl-xs-0">
                            <Link to="#"><img src="assets/img/logo/logo2.png" alt="Footer-logo" /></Link>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 text-center text-md-start">
                        <div className="copyright copy-line">
                            <h6>Copyright ©{new Date().getFullYear()} TextLink LTD. Designed & Developed
                                by <Link to="https://textlinksms.com" target={"_blank"}>TextLink dev team</Link></h6>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-4 col-md-12 text-center text-lg-end pr-25 pr-md-15 pr-xs-15" >
                        <div className="footer__social mb-10">
                            <Link to="#"><i className="fab fa-facebook-f"></i></Link>
                            <Link to="#"><i className="fab fa-twitter"></i></Link>
                            <Link to="#"><i className="fab fa-linkedin-in"></i></Link>
                            <Link to="#"><i className="fab fa-youtube"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
       <SearchPopUp/>
        </>
        
    )
}