import React from "react";
import { Row, Col,  Label, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Icon } from "../../components/Component";
import {Button} from "reactstrap";
const SendMessageForm = ({ alter, id, sendMessage, simCardId }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onFormSubmit = async (data) => {
    try {
      // Assuming `data.fullname` contains the phone number and `data.message` contains the text message
      const response = await sendMessage(data.fullname, data.message, "");
      // Optionally, you can clear the form or show a success message here
    } catch (error) {
      console.error("Failed to send message:", error);
      // Optionally, you can show an error message to the user here
    }
  };

  const formClass = classNames({
    "form-validate": true,
    "is-alter": alter,
  });

  return (
    <React.Fragment>
      <Form className={formClass} onSubmit={handleSubmit(onFormSubmit)}  style={{padding:4}}>
        <Row className="g-gs">
          <Col md="6">
            <div className="form-group">
              <Label className="form-label" htmlFor="fv-full-name">
                To
              </Label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  id="fv-full-name"
                  placeholder="(+1 4888 59590)"
                  {...register('fullname', { required: true })}
                  className="form-control" />
                {errors.fullname && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </Col>

          
          <Col md="12">
            <div className="form-group">
              <Label className="form-label" htmlFor="fv-message">
                Message
              </Label>
              <div className="form-control-wrap">
                <textarea
                  type="textarea"
                  className="form-control form-control-lg"
                  id="fv-message"
                  style={{fontSize:'1em'}}
                  {...register('message', {
                    required: true,
                  })}
                  placeholder="Write your message (for each new 128 characters, we send it as a new message)" />
                {errors.message && <span className="invalid">This field is required</span>}
              </div>
            </div>
          </Col>
         
               </Row>
               <Button className="btn-dim" color="primary" style={{marginTop:20, width:'100%'}}>
               <Icon name="send"><a style={{fontFamily:'sans-serif',fontSize:'0.7em'}}>Send</a></Icon>
                </Button>              
      </Form>
    </React.Fragment>
  );
};
export default SendMessageForm;
