
import {BrowserRouter as  Routes, Route, Link} from 'react-router-dom'
import HeaderOne from "../../../header/HeaderOne"
import * as React from 'react'
import FooterOne from '../../../footer/FooterOne'
import Head from '../../../components/Head'
function VerificationApi() {
  return (
<> 
<Head title=" SMS Verification API overview" />

    <HeaderOne />
 

    <section className="about-us-area pt-145 pb-90 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
        
			<div className="container" style={{marginTop:'10%'}}>
				<div className="row align-items-center">
                    
					<div className="col-lg-6">
                        
						<div className="about-text-wrapper2 mb-30 pr-75">
							<h6 className="semi-title semi-title2">SMS Verification api</h6>
							<h2 className="sect-title text-heding2 mb-35">Easily verify your users by their phone numbers.</h2>
                            <p className="paragraph-title">Phone verification api is easy to use, you don't have to store anything yourself.
                            We handle all of the logic on our side, you just send us the number that you want to verify and we will return you the code.
                            <br/>
                            <br/>

                           </p>
							<ul className="text-list text-list2 list-none border-bottom" style={{marginTop:20}}>
								<li>
									<div className="services-list">
										<h5 className="fs-20 text-heding2">Available packages</h5>
										<p>We have packages for JS, Python, PHP, C#</p>
									</div>
								</li>
								
								
										<Link to="https://docs.textlinksms.com/" target="_blank"  className="theme_btn mr-20">Check out our phone verification api documentation</Link>
							</ul>
							
						</div>
					</div>
					<div className="col-lg-6">
						<div className="about-img-wrapper mb-30">
							<img className="img-one img-fluid  d-xxl-inline-block rounded-3" src="assets/img/about/verificationapiImg1.png" alt="About Img" />
							
						</div>
					</div>
				</div>
			</div>
		</section>    
<section>
<div className="col-12" style={{display: 'flex',justifyContent: 'center',alignItems:'center',flexDirection:'column'}}>
    <ul className="btn-list2 list-none d-sm-flex align-items-center justify-content-center">
        <li><Link to="/faq" className="theme_btn mr-20">FAQ</Link></li>
        <li><Link to="/signup" className="theme_btn border-btn">Sign up <i className="fal fa-long-arrow-right"></i></Link></li>
    </ul>
</div>
    </section> 
	<br/>

    <FooterOne/>      
</>
  )
}
export default VerificationApi
