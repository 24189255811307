import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const subscriptions = [
  { name: 'Free', price: 0, messages: 500 },
  { name: 'Starter', price: 45, messages: 10000 },
  { name: 'Pro', price: 95, messages: 30000 },
  { name: 'Enterprise', price: 195, messages: 100000 }
];

function PricingCardNew() {
  const [isYearly, setIsYearly] = useState(false);
  const navigate = useNavigate();

  const toggleYearly = () => {
    setIsYearly(!isYearly);
  };

  return (
    <div className="pricing-cards">
      <div style={{ textAlign: 'center', marginBottom: '20px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
     
        <label className="switch123">
  <input type="checkbox" className="input123" checked={isYearly} onChange={toggleYearly} style={{ marginRight: '10px' }} />
  <span className="slider123"></span>

</label>
<span style={{marginLeft:20, fontWeight:'bold'}}>Yearly subscription (17% off)</span>

      </div>
      <div className="row gx-3">
        {subscriptions.map((sub, index) => {
          const monthlyPrice = sub.price;
          const yearlyPrice = (sub.price * 12 * 0.83).toFixed(2);

          return (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 mb-4">
              <div className={`pack-container-custom ${isYearly ? 'yearly' : 'monthly'} ${sub.name === 'Pro' ? 'highlight' : ''}`}>
                <div className="header-custom">
                  <p className="title-custom">{sub.name} {sub.name === 'Pro' && <span className="most-popular">Most Popular</span>}</p>
                  <div className="price-container-custom">
                    <span>$</span>{isYearly ? monthlyPrice*0.83 : monthlyPrice}
                    <span>/mo</span>
                  </div>
                  <div>${isYearly ? `${Math.round(monthlyPrice*0.83*12)}` : Math.round(monthlyPrice*12)}/year</div>
                </div>
                <div>
                  <ul className="lists-custom">
                    <li className="list-custom">
                      <span>
                        <svg aria-hidden="true" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.5 12.75l6 6 9-13.5" strokeLinejoin="round" strokeLinecap="round"></path>
                        </svg>
                      </span>
                      <p>{sub.messages} messages monthly</p>
                    </li>
                    <li className="list-custom">
                      <span>
                        {sub.name === 'Free' ? (
                          <svg aria-hidden="true" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 18L18 6M6 6l12 12" strokeLinejoin="round" strokeLinecap="round"></path>
                          </svg>
                        ) : (
                          <svg aria-hidden="true" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 12.75l6 6 9-13.5" strokeLinejoin="round" strokeLinecap="round"></path>
                          </svg>
                        )}
                      </span>
                      <p>24/7 customer support</p>
                    </li>
                    <li className="list-custom">
                      <span>
                        {sub.name === 'Pro' || sub.name === 'Enterprise' ? (
                          <svg aria-hidden="true" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.5 12.75l6 6 9-13.5" strokeLinejoin="round" strokeLinecap="round"></path>
                          </svg>
                        ) : (
                          <svg aria-hidden="true" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 18L18 6M6 6l12 12" strokeLinejoin="round" strokeLinecap="round"></path>
                          </svg>
                        )}
                      </span>
                      <p>Free setup help</p>
                    </li>
                    <li className="list-custom">
                      <span>
                        <svg aria-hidden="true" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4.5 12.75l6 6 9-13.5" strokeLinejoin="round" strokeLinecap="round"></path>
                        </svg>
                      </span>
                      <p>Access to all features</p>
                    </li>
                  </ul>
                </div>
                <div className="button-container-custom">
                <button type="button" onClick={() => navigate('/signup')}>
                {sub.name === 'Free' ? 'Sign Up' : 'Buy Now'}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PricingCardNew;
