export default function HomeWorkProcess(){
    return(
        <section className="work-process-area">
			<div className="container">
				<ul className="process-bg">
					<li>
						<div className="work-process">
							<div className="process-icon">
								<img src="assets/img/icon/p-1a.png" alt="Process Icon" />
							</div>
							<h6>Register<br />
								</h6>
						</div>
					</li>
					<li>
						<div className="work-process">
							<div className="process-icon">
								<img src="assets/img/icon/p-1b.png" alt="Process Icon" />
							</div>
							<h6>Set Up Your Device<br />
								</h6>
						</div>
					</li>
					<li>
						<div className="work-process">
							<div className="process-icon">
								<img src="assets/img/icon/p-1c.png" alt="Process Icon" />
							</div>
							<h6>Choose the tools<br />
								you need</h6>
						</div>
					</li>
					<li>
						<div className="work-process">
							<div className="process-icon">
								<img src="assets/img/icon/p-1d.png" alt="Process Icon" />
							</div>
							<h6>You are <br />
								all set</h6>
						</div>
					</li>
				</ul>
			</div>
		</section>
    )
}