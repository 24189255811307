import { Link } from "react-router-dom"

export default function TestimonialOne(){
    return(
        <section className="testimonial-area pt-110 pb-90 pt-md-5 pb-md-30 pt-xs-60 pb-xs-30" style={{marginTop:"200px"}}>
		<div className="container">
			<div className="row align-items-center">
				<div className="col-lg-6">
					<div className="testimonial-img-wrapper mb-30">
						
						<span className="images five"><img src="assets/img/logo/logo2.png" alt="T-icon" /></span>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="testimonial-text-wrapper mb-30 pl-30 pl-lg-0 pl-md-0 pl-xs-0">
						<div className="section-title mb-30">
							<h2 className="style-text">Sign up</h2>
							<h6 className="semi-title title-line">Sign up</h6>
							<h2 className="sect-title">Ready to get started?</h2>
						</div>
						<h2 className="paragraph-title mb-45"  style={{ color:'black', fontSize:'1.2em', fontWeight:"normal"}}>Try it out for free now. <br/><br/> You will get 500 free messages to test the service out, after that you can choose on of our plans or contact us for custom talored one.</h2>
						<div >
						<Link to="/signup" className="newButtonLink" style={{backgroundCollor:'white'}}>Start now, no Credit Card required</Link>

						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    )
}