import { Link } from "react-router-dom";

export default function WorkProcess(){
    return(
        <section className="core-feature-area about-core-bg pt-120 pb-120 pb-xs-120 pb-md-60 pt-md-60 pt-xs-60">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title text-center mb-60">
							<h6 className="semi-title">Fun Fact About Us</h6>
							<h2 className="sect-title lh-1">Company work process</h2>
						</div>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-lg-4 col-md-6">
						<div className="core-feature2 mb-30">
							<div className="feature-img pos-rel">
								<img className="w-100" src="assets/img/feature/feature-1.jpg" alt="Image" />
								<a href="services-details.html" className="theme_btn read-more">Read More <i className="fal fa-long-arrow-right"></i></a>
							</div>
							<div className="feature-content">
								<h4><a href="case-details.html">Become our partners?</a></h4>
								<p>We’re available for 8 hours a day! Contact to
									require a detailed analysis</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6">
						<div className="core-feature2 mb-30">
							<div className="feature-img pos-rel">
								<img className="w-100" src="assets/img/feature/feature-2.jpg" alt="Image" />
								<a href="services-details.html" className="theme_btn read-more">Read More <i className="fal fa-long-arrow-right"></i></a>
							</div>
							<div className="feature-content">
								<h4><a href="case-details.html">Our creative team</a></h4>
								<p>We’re available for 8 hours a day! Contact to
									require a detailed analysis</p>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6">
						<div className="core-feature2 mb-30">
							<div className="feature-img pos-rel">
								<img className="w-100" src="assets/img/feature/feature-3.jpg" alt="Image" />
								<Link to="/services-details" className="theme_btn read-more">Read More <i className="fal fa-long-arrow-right"></i></Link>
							</div>
							<div className="feature-content">
								<h4><Link to="/case-details">Our mission &amp; vision</Link></h4>
								<p>We’re available for 8 hours a day! Contact to
									require a detailed analysis</p>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<ul className="btn-list2 list-none d-sm-flex align-items-center justify-content-center">
							<li><Link to="/services" className="theme_btn mr-20">Our Services</Link></li>
							<li><Link to="/contact" className="theme_btn border-btn">Get In Touch <i className="fal fa-long-arrow-right"></i></Link></li>
						</ul>
					</div>
				</div>
			</div>
		</section>
    )
}