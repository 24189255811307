import BlogTwo from "../components/BlogTwo";
import Breadcrumb from "../components/BreadCurmb";
import CounterUpTwo from "../components/CounterUpTwo";
import ServicesOne from "../components/ServicesOne";
import WhyChooseUs from "../components/WhyChooseUs";
import FooterOne from "../footer/FooterOne";
import HeaderOne from "../header/HeaderOne";

export default function Services(){
	return(
		<>
			<HeaderOne/>
			<Breadcrumb title="What We do" subtitle="Responsive & functional IT design" pagetitle="Services"/>		
			<ServicesOne/>			
			<WhyChooseUs/>	
			<CounterUpTwo/>
			<BlogTwo/>			
			<FooterOne/>
		</>
	)
}