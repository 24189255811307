import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import { Icon } from "../../../../components/Component";
import Swal from "sweetalert2";
import Cookies from 'js-cookie';

export const CountriesTable = ({
  data,
  open,
  countrySearch,
  deleteItem
}) => {

  const deleteSenderDestination = async (simCardId, destinationCountry) => {
    const token = Cookies.get('token'); // Get the token from cookies
    const url = `${global.SERVIP}/user/delete-sender-destination`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        },
        body: JSON.stringify({ sim_card_id: simCardId, destination_country: destinationCountry })
      });

      if (!response.ok)
        return { ok: false, message: "Server error" };

      const data = await response.json();

      return { ok: data.ok, message: data.message || "Unknown error occurred" };
    } catch (error) {
      console.error('Error deleting sender destination:', error);
      return { ok: false, message: "Server error" };
    }
  };

  const deleteAlert = async (id, country) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteSenderDestination(id, country)
        if (!result.ok) {
          Swal.fire("Error!", result.message, "error");
        }
        else {
          if (deleteItem)
            deleteItem(country);
        }
      }
    });
  };

  return (
    data ? (
      <div style={{ maxHeight: '55vh', overflowY: 'auto' }}>
        <table className="table table-orders">
          <thead className="tb-odr-head">
            <tr className="tb-odr-item">
              <th className="tb-odr-info">
                <span className="tb-odr-id">Country Name</span>
              </th>
              <th className="tb-odr-amount">
                <span className="tb-odr-total">Seller</span>
              </th>
              <th className="tb-odr-amount">
                <span className="tb-odr-total">Earning per message</span>
              </th>
              <th className="tb-odr-action"></th>
            </tr>
          </thead>
          <tbody className="tb-odr-body">
            {data.filter(item =>
              item.countryName.toLowerCase().includes(countrySearch.toLowerCase()) ||
              item.destinationCountry.toLowerCase().includes(countrySearch.toLowerCase())
            ).map((item) => {
              return (
                <tr className="tb-odr-item" key={item.destinationCountry}>
                  <td className="tb-odr-info">
                    <span className="tb-odr-id">{item.countryName}</span>
                  </td>
                  <td className="tb-odr-amount">
                    <span className="amount">{item.seller ? "Yes" : "No"}</span>
                  </td>
                  <td className="tb-odr-amount">
                    <span className="amount">{item.seller ? ('$' + parseFloat(item.price)) : "-"}</span>
                  </td>
                  <td className="tb-odr-action"><img
                    src="https://cdn-icons-png.flaticon.com/512/6861/6861362.png"
                    alt="Delete"
                    onClick={() => {
                      deleteAlert(item.simCardId, item.destinationCountry)
                    }}
                    style={{ marginLeft: 5, fill: 'red', cursor: 'pointer' }}
                    width={20}
                    height={20}
                  />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    ) : <p style={{ margin: 10 }}>No countries added</p>
  );
};