export default function Subscribe(){
    return(
        <>
            <h6 className="widget-title text-black pb-20 mb-35">Subscribe Now</h6>
            <p style={{color:'black'}}>Sign up to receive the latest updates</p>
            <form className="subscribe-form">
                <div className="input-box mail-input mb-10">
                    <input type="text" style={{color:'black', backgroundColor:'white'}} placeholder="Enter email address" required/>
                </div>

                <button  className="newButtonLink" style={{width:'100%'}}>Subscribe Now <i
                        className="fal fa-long-arrow-right"></i></button>
            </form>
        </>
    )
}