import Head from "../../layout/head/Head"
import Content from "../../layout/content/Content"
import { Row, Col, Button } from "reactstrap"
import { Accordian, PreviewAltCard } from "../../components/Component"
import {
    SpecialTable,
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    BlockDes,
    BackTo,
    OrderTable,
    LoginLogTable,
} from "../../components/Component";
import {
    Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, CardBody, CardSubtitle, CardTitle, CardLink, CardText
} from "reactstrap";
import { Icon } from "../../components/Component"
import BulkSmsFaq from "../BulkSMS/components/BulkSmsFaq";
import { CampaignsTable } from "../BulkSMS/components/CampaignsTable";
import { RecipientGroupsTable } from "../BulkSMS/components/RecipientGroupsTable";
import UserGroup from "../BulkSMS/components/UserGroup";
import { useEffect, useState } from "react";
import CraftMessageDnd from "../BulkSMS/components/CraftMessageDnd";
import { PreviewCard } from "../../components/Component";
import SingleListDnd from "../../components/partials/dnd/SingleListDnd";
import CreateMessageComponent from "../BulkSMS/components/CreateMessageComponent";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import Cookies from "js-cookie"
import { Select } from 'antd';
import CreateSubuser from "./components/CreateSubuser";
import DeleteSubuser from "./components/DeleteSubuser";
import ChangeSubuserPassword from "./components/ChangeSubuserPassword";
import ChangeSubuserEmail from "./components/ChangeSubuserEmail";
const { Option } = Select;


function TeamManagement() {
    const token = Cookies.get('token');
    const [subusers, setSubusers] = useState([])

    const navigate = useNavigate()

    const fetchSubuserInfo = async (id) => {
        try {
            const url = `${global.SERVIP}/user/get-subusers`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: "{}"
            });
            if (!response.ok)
                return;
            const data = await response.json();
            if (!data.ok)
                return;

            setSubusers(data.subusers);
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        if (!token) {
            navigate("/signup")
        }
        fetchSubuserInfo()
    }, [])


    return (
        <>
            <Head title="Start SMS campaign" />
            <Content>
                <h4 style={{ marginBottom: 10 }}>Here you can add new team member accounts.</h4>
                <p style={{ marginBottom: 20 }}>Your team members can log in to their account, which has functionality restricted only to chat</p>

                <Row>
                    <Col lg="8">
                        <Card className="card h-100">
                            <CardBody className="card-inner" >
                                <CardTitle tag="h4">Manage team members</CardTitle>
                                <br></br>
                                <p>Manage your team member subaccounts. <br /> When you create an account, the e-mail address that you specified will receive the confirmation link, which your team member can use to set the password that he will be using to log in to his account. You can also manually set the user's password. </p>
                                <br />
                                <div style={{ marginBottom: '20px', display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
                                    <table className={`table table-tranxf`}>
                                        <thead>
                                            <tr className="tb-tnx-item">
                                                <th className="tb-tnx-info" style={{ paddingLeft: "8px"}}>E-mail address</th>
                                                <th className="tb-tnx-info">Status</th>
                                                <th className="tb-tnx-info"></th>
                                                <th className="tb-tnx-info"></th>
                                                <th className="tb-tnx-info"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subusers.map((item) => {
                                                return (
                                                    <tr key={item.id} className="tb-tnx-item">

                                                        <td className="tb-tnx-info" style={{ paddingLeft: "8px"}}>
                                                            <span className="title">{item.email || 'Error displaying email'}</span>
                                                        </td>
                                                        <td className="tb-tnx-info">
                                                            <span className="title">{item.password ? "Active" : "Pending Confirmation"}</span>
                                                        </td>
                                                        <td className="tb-tnx-info">
                                                            <ChangeSubuserEmail token={token} id={item.id} currentEmail={item.email} onChangeEmail={subuser => {
                                                                setSubusers(e => e.map(a => ((a.id === item.id) ? subuser : a)))
                                                            }}></ChangeSubuserEmail>
                                                        </td>
                                                        <td className="tb-tnx-info">
                                                            <ChangeSubuserPassword token={token} id={item.id} hasPassword={item.password} onChangePassword={subuser => {
                                                                setSubusers(e => e.map(a => ((a.id === item.id) ? subuser : a)))
                                                            }}></ChangeSubuserPassword>
                                                        </td>
                                                        <td className="tb-tnx-info">
                                                            <DeleteSubuser id={item.id} token={token} onDelete={() => {
                                                                setSubusers(e => e.filter(a => (a.id != item.id)))
                                                            }}></DeleteSubuser>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            {(subusers.length == 0) ? <tr><td colSpan={4} style={{ textAlign: "center" }}>You do not have team members. </td></tr> : null}
                                        </tbody>
                                    </table>
                                    <CreateSubuser token={token} onCreate={(subuser) => setSubusers(e => [...e, subuser])}></CreateSubuser>
                                </div>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className="card h-100">
                            <CardBody className="card-inner">
                                <CardTitle tag="h6">Having trouble?</CardTitle>
                                <p className="mb-2 ff-base">
                                    Click on create new button and just enter the email of the employee you want to add as user to your account.
                                    <br/>
                                    If you are having any issues, feel free to contact us.
                                </p>
                              
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </Content>
        </>
    );
}

export default TeamManagement