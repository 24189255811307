import {BrowserRouter as  Routes, Link, Route} from 'react-router-dom'
import HeaderOne from "../header/HeaderOne"
import * as React from 'react'
import FooterOne from '../footer/FooterOne'
import Breadcrumb from '../components/BreadCurmb'
import ContactForm from '../components/ContactForm'
import Head from '../components/Head'

export default function Contact() {
  return (
<> 
<Head title="Contact page" />

    <HeaderOne /> 
    <section className="contact-area pt-120 pb-90 pt-md-60 pb-md-60 pt-xs-60 pb-xs-60" style={{marginTop:"11%"}}>
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="section-title mb-60">
							<p className="fs-14 lh-1 mb-15">Get In Touch</p>
							<h2 className="sect-title lh-1">Let's help you!</h2>
						</div>
						<ContactForm />
					</div>
					<div className="col-lg-6">
						<div className="contact-short-info pr-50 pr-lg-0 pr-md-0 pr-xs-0 pl-80 pl-lg-0 pl-md-0 pl-xs-0">
							<div className="section-title mb-30">
								<p className="fs-14 lh-1 mb-15">Contact Us</p>
								<h2 className="sect-title lh-1">You can also reach us here</h2>
							</div>
						
							<div className="adress-box ab-2">
								<div className="icon-link" style={{paddingLeft:15}}>
									<i className="fas fa-envelope"></i>
								</div>
								<div className="address-content">
									<p className="fs-14">Email Address</p>
									<h6>info@textlinksms.com, sales@textlinksms.com</h6>
								</div>
							</div>
							<div className="adress-box">
								<div className="icon-link" style={{paddingLeft:17}}>
									<i className="fas fa-map-marker-alt"></i>
								</div>
								<div className="address-content">
									<p className="fs-14">Office Address</p>
									<h6>71-75 Shelton St, London WC2H 9JQ, UK</h6>
								</div>
							</div>
						
						</div>
					</div>
				</div>
			</div>
		</section>
    
    <FooterOne/>     
       
</>
  )
}
