import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';  

export default function BrandSlider(){
    return(
        <section className="brand-area pt-120 pb-80 pt-md-60 pt-xs-60 pb-md-25 pb-xs-25">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title text-center mb-60">
							<h6 className="semi-title">100+ Happy Users</h6>
							<h2 className="sect-title lh-1">Our experienced IT staffs</h2>
						</div>
					</div>
				</div>
				<div className="brand-border brand-slide">				
				<OwlCarousel items={5}  
                    className="owl-theme"  
                    loop 
                    center={true}
                    dots={false}			  
                    margin={30} 
					responsive= {
						{
							'1':{
								items: 2
							},
							'768': {
								items: 3
							},
		
							'1200': {
								items: 5
							}
		
						}
						
					}>				
                    <div className="brand-item">
                        <a className="brand-img" href="#">
                            <img className="mb-50 front" src="assets/img/brand/brand-1.png" alt="Brand" />
                            <img className="mb-50 back" src="assets/img/brand/brand-1g.png" alt="Brand" />
                        </a>
                    </div>
					<div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-2.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-2g.png" alt="Brand" />
						</a>
                    </div>
					<div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-3.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-3g.png" alt="Brand" />
						</a>
                    </div>
					<div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-4.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-4g.png" alt="Brand" />
						</a>
                    </div>
					<div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-5.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-5g.png" alt="Brand" />
						</a>
                    </div>
					<div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-1.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-1g.png" alt="Brand" />
						</a>
                    </div>

					<div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-2.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-2g.png" alt="Brand" />
						</a>
                    </div>

					<div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-3.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-3g.png" alt="Brand" />
						</a>
					</div>

                    <div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-4.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-4g.png" alt="Brand" />
						</a>
					</div>

                    <div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-5.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-5g.png" alt="Brand" />
						</a>
					</div>

                    <div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-1.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-1g.png" alt="Brand" />
						</a>
					</div>

                    <div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-2.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-2g.png" alt="Brand" />
						</a>
					</div>

                    <div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-3.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-3g.png" alt="Brand" />
						</a>
					</div>
                    <div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-4.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-4g.png" alt="Brand" />
						</a>
					</div>
                    <div className="brand-item">
						<a className="brand-img" href="#">
							<img className="mb-50 front" src="assets/img/brand/brand-5.png" alt="Brand" />
							<img className="mb-50 back" src="assets/img/brand/brand-5g.png" alt="Brand" />
						</a>
					</div>
                    </OwlCarousel>
                </div>
                   
				<div className="row">
					<div className="col-12 text-center">
						<p className="mb-30">1000+ worldwide happy users. <b><Link className="text-heding3" to="/contact">Get
									In Touch <i className="fal fa-long-arrow-right"></i></Link></b></p>
					</div>
				</div>
                		
			</div>
		</section>
    )
}