import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Form, Input, Button } from 'antd';
import { AuthWrapper } from './style';
import Swal from "sweetalert2";

function ForgotPassword() {
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const handleEmailSubmit = async (values) => {
    try {
      const response = await fetch(global.SERVIP + '/auth/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: values.email
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.ok) {
        await Swal.fire({
          icon: "success", //error
          title: "Check your email",
          text: "Link with instructions should be sent to your email address shortly. Don't forget to check spam folder. ",
          focusConfirm: false
        })
        navigate('/');
      } else {
        await Swal.fire({
          icon: "error", //error
          title: "Error",
          text: data.message,
          focusConfirm: false
        })
      }

    } catch (error) {
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Unknown error has occured",
        focusConfirm: false
      })
      console.error('Login error', error);
    }
    setEmailSubmitted(true);
  };

  return (
    
    <AuthWrapper>
      <p className="auth-notice">
        Go back to <NavLink to="/signin">Sign in</NavLink>
      </p>
      <div className="auth-contents">
        <Form
          name="forgotPassword"
          form={form}
          onFinish={handleEmailSubmit}
          layout="vertical"
        >
          <h3 style={{ marginBottom: '30px' }}>Forgot Password</h3>

          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' }
            ]}
            label="Email Address"
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType='submit' style={{ width: '100%' }}>
              Send email
            </Button>
          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default ForgotPassword;
