import { Select } from 'antd';
import Cookies from "js-cookie"
import { useEffect, useState } from 'react';

const { Option } = Select;

const CurrencySelect = ({ isCrypto, selectedCurrency, onChange }) => {
  const token = Cookies.get('token');
  const [currencies, setCurrencies] = useState([]);

  const getCurrencies = async () => {
    try {
      const url = `${global.SERVIP}/countries/get-currencies`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
      if (!response.ok)
        return;
      const data = await response.json();
      if (!data.ok)
        return;

      setCurrencies(data.currencies);
    }
    catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getCurrencies();
  }, [])

  return (
    <Select value={selectedCurrency} defaultValue={isCrypto ? "BTC" : "USD"} placeholder="Select currency" style={{ flexBasis: 'calc(25% - 10px)', flexGrow: 1, minWidth: '150px' }} onChange={onChange} // Add the onChange handler
    >
      {isCrypto ? (
        // Cryptocurrency options
        <>
          {currencies.filter(e => e.crypto).map(currency => <Option value={currency.code}>{currency.code} - {currency.name}</Option>)}
          {/* Add more cryptocurrencies as needed */}
        </>
      ) : (
        // Regular currency options
        <>
          {currencies.filter(e => !e.crypto).map(currency => <Option value={currency.code}>{currency.code} - {currency.name}</Option>)}

          {/* Add more regular currencies as needed */}
        </>
      )}
    </Select>
  );
};

export default CurrencySelect;
