import FeaturesTwo from "../../components/FeaturesTwo";
import FooterOne from "../../footer/FooterOne";
import HeaderOne from "../../header/HeaderOne";
import { Link } from "react-router-dom";
import Head from "../../components/Head";

function ToolsOverview() {
  return (
    <>
      <Head title="TextLink tools overview" />
      <HeaderOne />
      <div style={{ marginTop: '14%' }}></div>
      <section
        className="core-feature-area pt-120 pt-md-60 pt-xs-60 pb-100 pb-md-30 pb-xs-30 home-4"
        style={{ backgroundColor: 'white' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb-55">
                <h2 className="sect-title" style={{ color: 'black' }}>Types of SMS tools that we offer</h2>
                <p className="lh-1 mb-10" style={{ color: 'black', marginTop: 20 }}>
                  *These are ways of using TextLink to send messages, each tool can be used with both services.
                </p>
              </div>
            </div>
          </div>
          <div className="row gx-0">
            <div className="col-lg-6 col-md-12">
              <div className="core-feature4">
                <div className="core-content">
                  <div className="core-text">
                    <h3>SMS API's</h3>
                    <p>Check out the types of SMS API's that we offer.</p>
                  </div>
                </div>
                <ul className="text-list text-list2 list-none border-bottom" style={{ marginTop: 20 }}>
                  <li>
                    <div className="services-list">
                      <h5 className="fs-20 text-heding2">Regular SMS API</h5>
                      <p>We have packages for JS, Python, PHP, C#.</p>
                      <Link style={{ color: 'blue' }} to="/smsapi">Find out more</Link>
                    </div>
                  </li>
                  <li>
                    <div className="services-list">
                      <h5 className="fs-20 text-heding2">Verification API</h5>
                      <p>Send unlimited phone number verifications. Delivery is staggered but guaranteed.</p>
                      <Link style={{ color: 'blue' }} to="/verificationapi">Find out more</Link>
                    </div>
                  </li>
                  <li>
                    <div className="services-list">
                      <h5 className="fs-20 text-heding2">Bulk SMS API</h5>
                      <p>Send to unlimited phone numbers. Delivery is staggered but all messages will be delivered.</p>
                      <Link style={{ color: 'blue' }} to="/bulksmsapi">Find out more</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="core-feature4">
                <div className="core-content">
                  <div className="core-text">
                    <h3>No-code tools</h3>
                    <p>See what no-code bulk SMS tools are available.</p>
                  </div>
                </div>
                <ul className="text-list text-list2 list-none border-bottom" style={{ marginTop: 20 }}>
                  <li>
                    <div className="services-list">
                      <h5 className="fs-20 text-heding2">Advanced, zero code, Bulk SMS</h5>
                      <p>There is no limit for the number of phone numbers you can send, the delivery will not be all at once, but they will all be delivered.</p>
                      <Link style={{ color: 'blue' }} to="/bulksms">Find out more</Link>
                    </div>
                  </li>
                  <li>
                    <div className="services-list">
                      <h5 className="fs-20 text-heding2">Web SMS App</h5>
                      <p>Communicate with your employees, users, and prospects one-on-one using our web chat. Easily add contacts, send and receive messages.</p>
                      <Link style={{ color: 'blue' }} to="/web-sms-app">Find out more</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterOne />
    </>
  );
}

export default ToolsOverview;
