export const basicData = {
  header: ["#", "First", "Last", "Handle"],
  data: [
    {
      id: "1",
      first: "Mark",
      last: "Otto",
      handle: "@mdo",
    },
    {
      id: "2",
      first: "Jacob",
      last: "Thornton",
      handle: "@fat",
    },
    {
      id: "3",
      first: "Larry",
      last: "the bird",
      handle: "@twitter",
    },
  ],
};

export const transactionData = {
  header: ["#", "Bill for", "Issue Date", "Due date", "total", "status"],
  data: [
    {
      id: "4947",
      bill: "Enterprize Year Subscription",
      issue: "10-05-2019",
      due: "10-13-2019",
      total: "599.00",
      status: "Due",
    },
    {
      id: "4904",
      bill: "Maintenance Year Subscription",
      issue: "06-19-2019",
      due: "06-26-2019",
      total: "99.00",
      status: "Paid",
    },
    {
      id: "4829",
      bill: "Enterprize Year Subscription",
      issue: "10-04-2018",
      due: "10-12-2018",
      total: "599.00",
      status: "Paid",
    },
    {
      id: "4830",
      bill: "Enterprize Anniversary Subscription",
      issue: "12-04-2018",
      due: "14-12-2018",
      total: "399.00",
      status: "Paid",
    },
    {
      id: "4840",
      bill: "Enterprize Coverage Subscription",
      issue: "12-08-2018",
      due: "13-22-2018",
      total: "99.00",
      status: "Cancelled",
    },
  ],
};

export const orderData = [
  {
    iso2: "US",
    volume: 4599,
    price: 0.006,
    num_price:0.008 ,
    link_price:0.01
  },
  {
    iso2: "CA",
    volume: 4599,
    price: 0.008,
    num_price:0.01 ,
    link_price:0.015
  },
 
];

export const loginData = [
  {
    browser: "Chrome on Window",
    ip: "192.149.122.128",
    time: "11:34 PM",
    date: "",
    action: false,
  },
  {
    browser: "Mozilla on Window",
    ip: "86.188.154.225",
    time: "10:34 PM",
    date: "Nov 20, 2019",
    action: true,
  },
  {
    browser: "Chrome on iMac",
    ip: "192.149.122.128",
    time: "04:16 PM",
    date: "Oct 23, 2019",
    action: true,
  },
  {
    browser: "Mozilla on Window",
    ip: "86.188.154.225",
    time: "10:34 PM",
    date: "Nov 20, 2019",
    action: true,
  },
  {
    browser: "Chrome on Window",
    ip: "192.149.122.128",
    time: "04:16 PM",
    date: "Oct 23, 2019",
    action: true,
  },
  {
    browser: "Chrome on iMac",
    ip: "192.149.122.128",
    time: "11:41 PM",
    date: "Oct 15, 2019",
    action: true,
  },
  {
    browser: "Mozilla on Window",
    ip: "86.188.154.225",
    time: "05:43 AM",
    date: "Oct 13, 2019",
    action: true,
  },
  {
    browser: "Chrome on iMac",
    ip: "192.149.122.128",
    time: "04:12 AM",
    date: "Oct 03, 2019",
    action: true,
  },
];
