import { Nav, NavItem, NavLink, Row, Col, TabContent, TabPane } from "reactstrap";
import { Block, BlockHead, BlockHeadContent, BlockTitle, BlockDes, BackTo } from "../../../components/block/Block";
import { PreviewCard, CodeBlock } from "../../../components/preview/Preview";
import { useState } from "react";
import classnames from "classnames";
import { Icon } from "../../../components/Component";


function CodeSnippetTabs() {
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };
  return (
    <PreviewCard className="card h-100">
      <h5>Code snippets for sending SMS</h5>
      <Nav tabs>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeIconTab === "5" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleIconTab("5");
            }}
          >
            <Icon name="js" /> <span>JS/Node</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeIconTab === "6" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleIconTab("6");
            }}
          >
            <Icon name="python" /> <span>Python</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            tag="a"
            href="#tab"
            className={classnames({ active: activeIconTab === "7" })}
            onClick={(ev) => {
              ev.preventDefault();
              toggleIconTab("7");
            }}
          >
          </NavLink>
        </NavItem>

      </Nav>
      <TabContent activeTab={activeIconTab}>
        <TabPane tabId="5">
          <CodeBlock title="Installation" language="bash">
            {`npm install textlink-sms`}
          </CodeBlock>
          <CodeBlock title="Sending an SMS" language="js">
            {`const textlink = require("textlink-sms");
textlink.useKey("YOUR_API_KEY"); // Replace with your API key

textlink.sendSMS("+381611231234", "Dummy message text...");`}
          </CodeBlock>
        </TabPane>
        <TabPane tabId="6">
          <CodeBlock title="Installation" language="bash">
            {`pip install textlink`}
          </CodeBlock>
          <CodeBlock language="python">
            {`import textlink as tl

tl.useKey("YOUR_API_KEY") # Replace with your API key

result = tl.sendSMS("+381637443242", "Your message text...")
print(result)`}
          </CodeBlock>
        </TabPane>
      </TabContent>
    </PreviewCard>
  )
}

export default CodeSnippetTabs