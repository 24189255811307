import React, { useState, useEffect } from "react";
import { Icon } from "../../../components/Component";
import { Button } from "reactstrap";
import { loginData, transactionData } from "../../../components/table/TableData";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from "reactstrap";
import './campaign.css'
import Cookies from "js-cookie"

export const CampaignsTable = ({ action, isCompact, data }) => {
  const token = Cookies.get('token');
  const [campaigns, setCampaigns] = useState([])

  const fetchCampaigns = async () => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/get-all`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
      if (!response.ok)
        return;
      const data = await response.json();
      if (!data.ok)
        return;

      setCampaigns(data.campaigns.map(e => {
        if (e.bulk_job.sent_messages + e.bulk_job.failed_messages == e.bulk_job.total_messages)
          e.status = 'Done';
        else if (e.bulk_job.paused)
          e.status = 'Paused';
        else
          e.status = 'OnGoing';
        return e;
      }));
    }
    catch (e) {
      console.log(e);
    }
  }
  const pauseCampaign = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/pause`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      return true;
    }
    catch (e) {
      console.log(e);
      return false;
    }
  }
  const resumeCampaign = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/resume`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      return true;
    }
    catch (e) {
      return false;
    }
  }

  const deleteCampaign = async (id) => {
    try {
      const url = `${global.SERVIP}/outreach/campaigns/delete`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        },
        body: JSON.stringify({ id })
      });
      if (!response.ok)
        return false;
      const data = await response.json();
      if (!data.ok)
        return false;

      return true;
    }
    catch (e) {
      return false;
    }
  }

  useEffect(() => {
    fetchCampaigns();
  }, [])

  const DropdownTrans = ({ item }) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle tag="a" className="text-soft dropdown-toggle btn btn-icon btn-trigger">
          <Icon name="more-h"></Icon>
        </DropdownToggle>
        <DropdownMenu end>
          <ul className="link-list-plain">
            {(item.status == 'Paused') ? <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={async (ev) => {
                  ev.preventDefault();
                  setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Please wait" }) : (e))))
                  if (await resumeCampaign(item.id))
                    setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "OnGoing" }) : (e))))
                  else
                    setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Error" }) : (e))))
                }}
              >
                Resume
              </DropdownItem>
            </li> : <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={async (ev) => {
                  ev.preventDefault();
                  setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Please wait" }) : (e))))
                  if (await pauseCampaign(item.id))
                    setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Paused" }) : (e))))
                  else
                    setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Error" }) : (e))))
                }}
              >
                Pause
              </DropdownItem>
            </li>}

            <li>
              <DropdownItem
                tag="a"
                href="#dropdownitem"
                onClick={async (ev) => {
                  ev.preventDefault();
                  setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Please wait" }) : (e))))
                  if (await deleteCampaign(item.id))
                    setCampaigns(old => old.filter(e => (e.id != item.id)))
                  else
                    setCampaigns(old => old.map(e => ((e.id == item.id) ? ({ ...e, status: "Error" }) : (e))))
                }}
              >
                Delete
              </DropdownItem>
            </li>
          </ul>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };
  return (
    <div className="campaign-table-container" style={{ maxHeight: 500, paddingBottom: 150 }}>
      <table className="table table-tranx1">
        <thead>
          <tr>
            <th>Name</th>
            <th>Sent</th>
            <th>Start</th>
            <th>Cost</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((item, index) => (
            <tr key={index} b={(() => { console.log() })()}>
              <td>{item.name || "Unnamed"}</td>
              <td>{item.bulk_job.sent_messages}</td>
              <td>{new Date(item.createdAt).toLocaleDateString()}</td>
              <td>${parseFloat(item.bulk_job.total_paid)}</td>
              <td>
                <Badge color={item.status === "Done" ? "success" : item.status === "OnGoing" ? "warning" : item.status === "Please wait" ? "primary" : "danger"}>
                  {item.status}
                </Badge>
              </td>
              <td><DropdownTrans item={item} /></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
