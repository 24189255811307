import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import HeaderSearch from "../header-search/HeaderSearch";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useTheme, useThemeUpdate } from '../provider/Theme';

const Header = ({ fixed, className, ...props }) => {
  const [userEmail, setUserEmail] = useState("")
  const isSubUser = Cookies.get("subuser") == "true"
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });
  const getUserEmail = async () => {
    try {
      const token = Cookies.get('token'); // Retrieve the token from cookies
      if (!token) {
        console.log('No token found');
        return null;
      }
      const urlFunction = isSubUser? "get_subuser_info" : "get_user_info"
      const response = await fetch(global.SERVIP + "/user/"+ urlFunction, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Set the Authorization header with the token
        },
      });

      if (!response.ok) {
        console.error('Failed to retrieve email:', response.status);
        return null;
      }

      const data = await response.json();
      setUserEmail(data.info.email)
      return data;
    } catch (error) {
      console.error('Error while fetching user email:', error);
      return null;
    }
  };
  useEffect(() => {
    getUserEmail()
  }, [])
  return (
    <div className={ headerClass}>
      {
        <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ms-n1">
              <Toggle
                className="nk-nav-toggle nk-quick-nav-icon d-xl-none ms-n1"
                icon="menu"
                click={themeUpdate.sidebarVisibility}
              />
            </div>
            
            <div className="nk-header-search " style={{width:'100%'}}>
              <HeaderSearch />
            </div>
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
  
  
                <li className="user-dropdown">
                  <User email={userEmail}/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
   
    </div>
  );
};

//ako je user skloni header search
export default Header;