import { Link } from 'react-router-dom';
export default function BlogTwo(){
    return(
        <section id="news" className="grey-bg2 blog-area pt-120 pb-95 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<div className="section-title text-center mb-60">
							<h6 className="semi-title lh-1 mb-2">News Feeds</h6>
							<h2 className="sect-title">Company Blog & Insights</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div className="blogs-2">
							<div className="blogs__thumb">
								<Link to="/news-details">
									<img className="w-100" src="assets/img/blog/blog-16.jpg" alt="Blog Img" />
								</Link>
							</div>
							<div className="blogs__content">
								<div className="blogs-2-meta">
									<span><i className="far fa-user"></i> Admin</span>
									<span><i className="fal fa-calendar-alt"></i> 24th June 2022</span>
								</div>
								<h3><Link className="blog-title"to="/news-details">5 Ways Technology Has Improved
										Business Today</Link></h3>
							</div>
						</div>
					</div>
					<div className="col-lg-6 blog-pl-border">
						<div className="blogs-3">
							<div className="blogs__thumb">
								<Link to="/news-details">
									<img src="assets/img/blog/blog-6.jpg" alt="Blog Img" />
								</Link>
							</div>
							<div className="blogs__content">
								<div className="blogs-2-meta">
									<span><i className="far fa-user"></i> Admin</span>
									<span><i className="fal fa-calendar-alt"></i> 24th June 2022</span>
								</div>
								<h4><Link className="sub-title"to="/news-details">Grow Customer Relationships
										Via Technology Solutions.</Link></h4>
							</div>
						</div>
						<div className="blogs-3">
							<div className="blogs__thumb">
								<Link to="/news-details">
									<img src="assets/img/blog/blog-7.jpg" alt="Blog Img" />
								</Link>
							</div>
							<div className="blogs__content">
								<div className="blogs-2-meta">
									<span><i className="far fa-user"></i> Admin</span>
									<span><i className="fal fa-calendar-alt"></i> 24th June 2022</span>
								</div>
								<h4><Link className="sub-title"to="/news-details">Grow Customer Relationships
										Via Technology Solutions.</Link></h4>
							</div>
						</div>
						<div className="blogs-3">
							<div className="blogs__thumb">
								<Link to="/news-details">
									<img src="assets/img/blog/blog-8.jpg" alt="Blog Img" />
								</Link>
							</div>
							<div className="blogs__content">
								<div className="blogs-2-meta">
									<span><i className="far fa-user"></i> Admin</span>
									<span><i className="fal fa-calendar-alt"></i> 24th June 2022</span>
								</div>
								<h4><Link className="sub-title"to="/news-details">Grow Customer Relationships
										Via Technology Solutions.</Link></h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>	
    )
}