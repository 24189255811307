import Head from "../../layout/head/Head"
import Content from "../../layout/content/Content"
import { Row, Col, Button } from "reactstrap"
import { Accordian, PreviewAltCard } from "../../components/Component"
import {
  SpecialTable,
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  OrderTable,
  LoginLogTable,
} from "../../components/Component";
import {
  Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, CardBody, CardSubtitle, CardTitle, CardLink, CardText
} from "reactstrap";
import { Icon } from "../../components/Component"
import BulkSmsFaq from "./components/BulkSmsFaq";
import { CampaignsTable } from "./components/CampaignsTable";
import { RecipientGroupsTable } from "./components/RecipientGroupsTable";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import Cookies from "js-cookie";

function BulkSmsDasboard() {
  const token = Cookies.get('token');
  const navigate = useNavigate()
  
  useEffect(() => {
    if(!token)
    {
      navigate("/signup")
    }
   
}, []);

  return (
    <>
      <Head title="Bulk SMS Dashboard" />

      <Content>
        <h4 style={{ marginBottom: 10 }}>This is your Bulk SMS dashboard</h4>
        <p style={{ marginBottom: 20 }}>Create and monitor your campaigns. Create and change recipient groups.</p>


        <Col>
          <Row style={{ display: 'flex', alignItems: 'stretch' }}>
            <Col lg="7" className="mb-4 mb-lg-0" >
              <Card className="card h-100" >
                <CardBody className="card-inner">
                  <CardTitle tag="h5">How does TextLink Bulk SMS work? </CardTitle>
                  <br />
                  <p tag="h6" className="mb-2 ff-base">
                    If you want to send large number of similar messages, this is the place for you. Just create a recipient group (from excel or other type of file) and you can use our Bulk sms tools.
                  </p>
                  <br />
                  <p tag="h6" className="mb-2 ff-base">
                    Basic tool if if you want to send the same message to everyone and advanced one is if you want to send custom messages based on different data fields.
                  </p>
                  <p tag="h6" className="mb-2 ff-base">
                    You can choose sender country, but only from SIM card that you have available.
                  </p>
                  <br />

                </CardBody>
              </Card>
            </Col>
            <Col lg="5" className="mb-4 mb-lg-0">
              <Card className="card h-100" style={{ padding: 20 }} >
                <h5 style={{ margin: 10 }}>Your recipient groups</h5>
                <p>Each group can have different parameters like name, age,... (whatever the name of the column is). The only mandatory field is phone_number. <br />Check out this <a target="_blank" style={{ color: "blue" }} href="https://www.youtube.com/watch?v=-gajhLRMYIE">Video</a> for reference. </p>
                <RecipientGroupsTable />
              </Card>
            </Col>
          </Row>

          <Row className="my-3">
            <Col lg="8" className="mb-4 mb-lg-0" >
              <Card className="card h-100" style={{ padding: 20 }}>
                <h5 style={{ margin: 10 }}>Your campaigns</h5>
                <div style={{}}>
                  <Button className="btn-dim" outline color="primary" style={{ margin: 10, }} onClick={() => { navigate("/dashboard/bulksms-create") }}> Create new campaign</Button>


                </div>
                <CampaignsTable />
              </Card>
            </Col>

            <Col lg="4" >
              <Card className="card h-100" style={{ padding: 20 }}  >
                <h5>Bulk SMS FAQ</h5>

                <BulkSmsFaq />
              </Card>
            </Col>
          </Row>

        </Col>
      </Content>
    </>
  )
}

export default BulkSmsDasboard