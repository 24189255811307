import React, { useState } from 'react';
import { Button } from 'reactstrap'; // Assuming you're using reactstrap for Button

const CopyButton = ({ copyText }) => {
  const [buttonText, setButtonText] = useState("Copy wallet address");

  const handleCopy = () => {
    navigator.clipboard.writeText(copyText).then(() => {
      setButtonText("Copied");
      setTimeout(() => setButtonText("Copy"), 3000); // Reset text after 3 seconds
    }, (err) => {
      console.error('Failed to copy text:', err);
    });
  };

  return (
    <Button className="btn-dim btn-sm" outline color="primary" onClick={handleCopy}>
      {buttonText}
    </Button>
  );
};

export default CopyButton