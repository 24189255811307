import { Link } from "react-router-dom";
import MetisMenu from '@metismenu/react';
import Cookies from "js-cookie";

export default function SideBar(){
	const signedIn = (Cookies.get('token')?(Cookies.get('token').length>5 && true):false)
    const signedInButtonStyle ={ backgroundColor: "#eff7ff",borderRadius: "45px",color: "#086ad8", display:'flex', justifyContent:"center", alignItems: 'center', marginTop:10}

    const SideBarRemove = () => {
        document.body.classList.remove('on-side');
    };
    return(
        <aside className="slide-bar off-canvas">
		<div className="close-mobile-menu"  onClick={SideBarRemove}>
			<i className="fas fa-times"></i>
		</div>
		

	
		<nav className="side-mobile-menu">
			<MetisMenu id="mobile-menu-active">
			<li><Link to="/">Home</Link></li>
			
				<li className="has-dropdown">
					<Link to="services">Products</Link>
					<ul className="sub-menu">
					<li><Link to="/smstoolsoverview">Overview</Link></li>

						<li>
						<Link to="/web-sms-app">Web Texting App</Link>		

						</li>
						<li >
							<Link to="/smsapi">SMS API</Link>							
						</li>
						<li >
							<Link to="/bulksms">No Code Bulk SMS</Link>							
						</li>
					</ul>
				</li>
				<li className="has-dropdown">
					<Link to="services">Industries</Link>
					<ul className="sub-menu">
					<li><Link to="/trucking">Trucking</Link></li>

						<li>
						<Link to="/sms-marketing">Marketing</Link>		

						</li>
						<li >
							<Link to="/saas">SAAS</Link>							
						</li>
					
					</ul>
				</li>
				<li><Link to="/pricing">Pricing</Link></li>

				<li><Link to="/contact">Contact</Link></li>
				<li className={!signedIn&&"nav-item"} style={signedIn?signedInButtonStyle:{}}>
                    <a className={signedIn?"theme_btn quote-btn":"nav-link"}  href={signedIn?"/dashboard":"/signin"}>{signedIn?"Dashboard":"SignIn"}</a>
                </li>
                {!signedIn&&<li className="nav-item">
                    <a className="nav-link" href="/signup">SignUp</a>
                </li>}
				
			</MetisMenu>
		</nav>
		
	</aside>
    )
}