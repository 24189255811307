import HeaderOne from "../../header/HeaderOne";
import FooterOne from "../../footer/FooterOne";
import Head from "../../components/Head";
import PricingCardNew from "../../components/PricingCardNew";

function UseYourOwnNumbersPricing() {
  return (
    <>
      <Head title="Use Your Own Numbers Service Pricing" />
      <HeaderOne />
      <section
        className="core-feature-area pt-100 pt-md-60 pt-xs-120 pb-100 pb-md-30 pb-xs-30 home-5"
        style={{ marginTop: "13%" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb-55">
                <p className="lh-1 mb-10">Price &amp; Plans</p>
                <h2 className="sect-title">Choose The One That Suits You</h2>
              </div>
            </div>
          </div>
          <div className="row gx-0 justify-content-center">
            <PricingCardNew />
          </div>
        </div>
      </section>
      <FooterOne />
    </>
  );
}

export default UseYourOwnNumbersPricing;
